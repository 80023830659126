import React from "react";
import tawkTo from 'tawkto-react';
import axios from "axios";
import {
  Badge,
  Button,
  Container,
  Row,
  Col,
} from "reactstrap";
import "assets/scss/am-group.scss?v1.1.0";

import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footers/Footer.js";
import Features from "components/Features/Features.js";
import Reminder from "components/Reminder/Reminder";
import FormModal from "components/FormModal/FormModal.js";
import Services from "components/Services/Services";

const tawkToPropertyId = '5f668b22f0e7167d0011eb72';

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
        services: []
     }
  this.baseUrl = "https://am-group.herokuapp.com/api/v1";
}
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;

    // APIs
    this.getAllServices();
    tawkTo(tawkToPropertyId)
  }
  toggleModal = () => {
    this.setState({
        isOpen: !this.state.isOpen,
    });
  };
  getAllServices = async () => {
    await axios.get(`${this.baseUrl}/services`).then(res => {
      if (res.data) {
        this.setState({ services: res.data.data });
      }
    }); 
  };
  render() {
    return (
      <>
        <Navbar />
        <main ref="main">
          <div className="position-relative">
            <section className="section section-lg bg-gradient-default pb-250">
              <div className="shape shape-style-1 shape-default">
              </div>
              <Container fluid className="py-lg-md d-flex">
                <div className="col px-0 pt-5 pl-md-5 pl-lg-5">
                  <Row>
                    <Col lg="5">
                      <h1 className="display-3 text-white">
                        We're dedicated to crashing barriers of Africa’s technological progress 
                      </h1>
                      <p className="lead text-white">
                        We are a pan African firm dedicated to crashing barriers 
                        of Africa’s technological progress by offering services 
                        that offers high-tech tools such as apps, expand the 
                        community of developers, support the growth of upcoming 
                        businesses, and connect investors to companies in need. 
                      </p>
                      <div className="btn-wrapper">
                        <Button
                          className="btn-icon mb-3 mb-sm-0"
                          color="success"
                          type="button"
                          onClick={() => this.toggleModal()}
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fa fa-envelope"></i>
                          </span>
                          <span className="btn-inner--text">Interested? contact us</span>
                        </Button>
                        <FormModal 
                          isOpen={this.state.isOpen} 
                          onHandleOpen={() => this.toggleModal()} />
                      </div>
                    </Col>
                    <Col lg="7">
                      <h1 className="display-3 text-white">
                      Why choose to work with us
                      </h1>
                      <h6 className="pt-1 text-white">
                        We brought to you different services <i className="fa fa-hand-o-down" aria-hidden="true"></i>
                      </h6>
                      <Row>
                        <Col md="6">
                          <h6 className="text-success pt-1">We upskill young and older programmers in different technologies: </h6>
                          <ul className="list-unstyled mt-3">
                            <li className="py-2">
                              <div className="d-flex align-items-center">
                                <div>
                                  <Badge
                                    className="badge-circle mr-3"
                                    color="success"
                                  >
                                    <i className="fa fa-check" aria-hidden="true"></i>
                                  </Badge>
                                </div>
                                <div>
                                  <h6 className="mb-0 white-text">
                                    IOS & Android
                                  </h6>
                                </div>
                              </div>
                            </li>
                            <li className="py-2">
                              <div className="d-flex align-items-center">
                                <div>
                                  <Badge
                                    className="badge-circle mr-3"
                                    color="success"
                                  >
                                    <i className="fa fa-check" aria-hidden="true"></i>
                                  </Badge>
                                </div>
                                <div>
                                  <h6 className="mb-0 white-text">React native & Flutter</h6>
                                </div>
                              </div>
                            </li>
                            <li className="py-2">
                              <div className="d-flex align-items-center">
                                <div>
                                  <Badge
                                    className="badge-circle mr-3"
                                    color="success"
                                  >
                                    <i className="fa fa-check" aria-hidden="true"></i>
                                  </Badge>
                                </div>
                                <div>
                                  <h6 className="mb-0 white-text">
                                  Enterprise software development & Windows app development
                                  </h6>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </Col>

                        <Col md="6">
                        <h6 className="text-success pt-1">We also, </h6>
                          <ul className="list-unstyled mt-3">
                            <li className="py-2">
                              <div className="d-flex align-items-center">
                                <div>
                                  <Badge
                                    className="badge-circle mr-3"
                                    color="success"
                                  >
                                    <i className="fa fa-check" aria-hidden="true"></i>
                                  </Badge>
                                </div>
                                <div>
                                  <h6 className="mb-0 white-text">
                                    Connect investors or donors to institutions
                                  </h6>
                                </div>
                              </div>
                            </li>
                            <li className="py-2">
                              <div className="d-flex align-items-center">
                                <div>
                                  <Badge
                                    className="badge-circle mr-3"
                                    color="success"
                                  >
                                    <i className="fa fa-check" aria-hidden="true"></i>
                                  </Badge>
                                </div>
                                <div>
                                  <h6 className="mb-0 white-text">
                                    Connect individuals seeking for investments to organizations
                                  </h6>
                                </div>
                              </div>
                            </li>
                            <li className="py-2">
                              <div className="d-flex align-items-center">
                                <div>
                                  <Badge
                                    className="badge-circle mr-3"
                                    color="success"
                                  >
                                    <i className="fa fa-check" aria-hidden="true"></i>
                                  </Badge>
                                </div>
                                <div>
                                  <h6 className="mb-0 white-text">
                                    We got you Innovation, Startup & Entrepreneurship services 
                                  </h6>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </Col>
                        <Col lg="12">
                          <h1 className="display-3 text-white pt-3">
                            Learn more about our services <i className="fa fa-hand-o-down" aria-hidden="true"></i>
                          </h1>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    
                  </Row>
                </div>
              </Container>
            </section>
          </div>
          <Services />
          <Reminder />
          
          <Features />
        </main>
        <Footer />
      </>
    );
  }
}

export default About;
