import React from "react";
import tawkTo from "tawkto-react";
import { Container, Row, Col, UncontrolledCarousel } from "reactstrap";
import "assets/scss/am-group.scss?v1.1.0";

import { initiatives } from "../data/initiatives";

import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footers/Footer.js";
import Features from "components/Features/Features.js";
import Reminder from "components/Reminder/Reminder";
import Services from "components/Services/Services";

const frontItems = [
  {
    src: require("assets/img/initiatives/coding.jpeg"),
    altText: "",
    caption: "",
    header: "",
  },
  {
    src: require("assets/img/initiatives/fkode.png"),
    altText: "",
    caption: "",
    header: "",
  },
  {
    src: require("assets/img/initiatives/incikelogo.jpg"),
    altText: "",
    caption: "",
    header: "",
  },
];
const tawkToPropertyId = "5f668b22f0e7167d0011eb72";

class Initiative extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
    tawkTo(tawkToPropertyId);
  }

  render() {
    const clickedInitiative = localStorage.getItem("initiative");
    const parsedId = parseInt(clickedInitiative, 10);
    return (
      <>
        <Navbar />
        <main ref="main">
          <div className="position-relative">
            <section className="section section-lg bg-gradient-default pb-250">
              <div className="shape shape-style-1 shape-default"></div>
              <Container fluid className="py-lg-md d-flex">
                <div className="col px-0 pt-5 pl-md-5 pl-lg-5">
                  <Row>
                    <Col lg="6">
                      {initiatives.map((initiative) => {
                        if (initiative.id === parsedId) {
                          return (
                            <div key={initiative.id}>
                              <h1 className="display-3 text-white">
                                {initiative.initiative}
                              </h1>
                              <p className="lead text-white">
                                {initiative.details}
                              </p>
                            </div>
                          );
                        }
                        return null;
                      })}
                      <h1 className="display-3 text-white pt-3">
                        Take a look at what we offer{" "}
                        <i className="fa fa-hand-o-down" aria-hidden="true"></i>
                      </h1>
                    </Col>
                    <Col lg="6">
                      <UncontrolledCarousel items={frontItems} />
                    </Col>
                  </Row>
                </div>
              </Container>
            </section>
          </div>
          <Services />
          <Reminder />

          <Features />
        </main>
        <Footer />
      </>
    );
  }
}

export default Initiative;
