import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import "assets/scss/am-group.scss?v1.1.0";

import { services } from "../../data/services";
class Reminder extends Component {
  setToLocalStorage = (service) => {
    localStorage.setItem("service", service.id);
  };
  render() {
    return (
      <section className="section section-lg bg-gradient-default">
        <Container className="">
          <Row className="text-center justify-content-center">
            <Col lg="10">
              <h2 className="display-3 text-white">
                Keep it in mind as you scroll up!
              </h2>
              <p className="lead text-white">
                We offer you services that offers high-tech tools such as apps,
                expand the community of developers, support the growth of
                upcoming businesses, and connect investors to companies in need.
              </p>
            </Col>
          </Row>
          <Row className="row-grid mt-5">
            {services.map((service) => {
              return (
                <Col lg="3" key={service.id}>
                  <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-success">
                    {service.id === 1 ? (
                      <i className="fa fa-android" aria-hidden="true"></i>
                    ) : service.id === 2 ? (
                      <i className="fa fa-building" aria-hidden="true"></i>
                    ) : service.id === 3 ? (
                      <i className="fa fa-globe" aria-hidden="true"></i>
                    ) : service.id === 4 ? (
                      <i className="fa fa-money" aria-hidden="true"></i>
                    ) : (
                      <i className="fa fa-list" aria-hidden="true"></i>
                    )}
                  </div>
                  <div>
                    <h5 className="text-white mt-3">{service.service}</h5>
                  </div>
                  <p className="text-white mt-3">
                    {service.details.substr(0, 100)}...
                    <a
                      className="text-success"
                      onClick={() => this.setToLocalStorage(service)}
                      href={`/service/${service.service}`}
                    >
                      Learn more
                    </a>
                  </p>
                </Col>
              );
            })}
          </Row>
        </Container>
      </section>
    );
  }
}

export default Reminder;
