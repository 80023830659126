import React from "react";
import axios from "axios";
import {
  Card,
  CardHeader,
  CardBody,
  Modal
} from "reactstrap";
import "assets/scss/am-group-dashboard.scss";

class FeaturesModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            feature: []
         }
        this.baseUrl = `https://am-group.herokuapp.com/api/v1`;
    }
    toggleModal = () => {
        this.props.onHandleOpen();
    };

    componentDidUpdate(prevProps, props) {
        if(this.props.ftId !== prevProps.ftId && this.props.isOpen) {
            this.getOnefeature(prevProps.ftId);
        }
    }

    getOnefeature = async () => {
        await axios.get(`${this.baseUrl}/feature/${this.props.ftId}`).then(res => {
            if (res.data) {
            this.setState({ feature: res.data.data });
            }
        }); 
    };
  render() {
      const { isOpen } = this.props;

    return (
      <>
        <Modal
            className="modal-dialog-centered"
            size="sm"
            isOpen={isOpen}
            toggle={() => this.toggleModal()}
        >
            <div className="modal-body p-0">
                {this.state.feature.map(f => {
                    return (
                        <Card className="bg-secondary shadow border-0">
                            <CardHeader className="pb-3">
                            <div className="text-muted text-center mb-3">
                            <h5>Feature id: {f.id}</h5>
                            </div>
                            </CardHeader>
                            <CardBody>
                            <div className="text-center text-muted mb-4">
                                <small>{f.featuredlink}</small>
                            </div>
                            
                            </CardBody>
                        </Card>
                    )
                })}
            </div>
        </Modal>
      </>
    );
  }
}

export default FeaturesModal;
