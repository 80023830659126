import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/owl.carousel';

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
  
import AdminLayout from "./layouts/Admin.js";

import Landing from "views/Landing.js";
import Login from "views/Login.js";
import ForgetPassword from "views/ForgetPassword";
import ResetPassword from "views/ResetPassword";
import Servise from "views/Service.js";
import Initiative from "views/initiative";
import About from "views/About.js";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/admin" render={props => <AdminLayout {...props} />} />
      <Route 
        path="/" 
        exact 
        render={props => <Landing {...props} />} />
      <Route 
        path="/auth/am-group-admin" 
        exact 
        render={props => <Login {...props} />} />
      <Route 
        path="/auth/forget-password" 
        exact 
        render={props => <ForgetPassword {...props} />} />
      <Route 
        path="/auth/reset" 
        exact 
        render={props => <ResetPassword {...props} />} />
      <Route
        path="/service/:service"
        exact
        render={props => <Servise {...props} />}
      />
      <Route
        path="/initiative/:initiative"
        exact
        render={props => <Initiative {...props} />}
      />
      <Route
        path="/about-us"
        exact
        render={props => <About {...props} />}
      />
      <Redirect to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
