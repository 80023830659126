import React from "react";
import {
  Container
} from "reactstrap";

import "assets/scss/am-group-dashboard.scss";
import Header from "components/Headers/Header.js";
import Messages from "components/Messages/Messages";

class Index extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      activeNav: 1
    };
  }
  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({ activeNav: index });
  };
  render() {
    return (
      <>
        <Header />
        <Container className="mt--7" fluid>
          <Messages />
        </Container>
      </>
    );
  }
}

export default Index;
