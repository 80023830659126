import React from "react";
import axios from "axios";
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import "assets/scss/am-group-dashboard.scss";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
        errorMessage: null,
        messages: 0,
        users: 0
     }
  this.baseUrl = "https://am-group.herokuapp.com/api/v1";
  }
  componentDidMount() {
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem(
      "token"
    );
    this.getNumbrOfMessages();
    this.getNumbrOfUsers();
  }
  getNumbrOfMessages = async () => {
    await axios.get(`${this.baseUrl}/admin/messages/count`).then(res => {
      if (res.data) {
        this.setState({ messages: res.data.messages });
      }
    }); 
  };
  getNumbrOfUsers = async () => {
    await axios.get(`${this.baseUrl}/admin/messages/users/count`).then(res => {
      if (res.data) {
        this.setState({ users: res.data.user });
      }
    }); 
  };
  render() {
    return (
      <>
        <div className="header bg-gradient-default pb-8 pt-5 pt-md-8">
          <Container fluid>
            <div className="header-body">
              <Row>

                <Col lg="6" xl="6">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Total messages
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {this.state.messages < 0 ?
                              "No messages yet":
                              this.state.messages}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                            <i className="fa fa-envelope"></i>
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-nowrap">You have</span>{" "}
                        <span className="text-success mr-2">
                          {this.state.messages < 0 ?
                              "no messages yet":
                              `${this.state.messages} messages`}
                        </span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>

                <Col lg="6" xl="6">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Total users
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {this.state.users < 0 ?
                              "No users yet":
                              this.state.users}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                            <i className="fa fa-user" aria-hidden="true"></i>
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="mr-2">
                          {this.state.users < 0 ?
                              "No one has contacted you yet":
                              `Total number of contacts is ${this.state.messages} `}
                        </span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>

              </Row>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

export default Header;
