import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Redirect } from 'react-router-dom';
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media
} from "reactstrap";
import "assets/scss/am-group-dashboard.scss";

class AdminNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      adminInfo: [],
      redirect:false
     }
  this.baseUrl = "https://am-group.herokuapp.com/api/v1";
  this.logout = this.logout.bind(this);
  }
  logout(e) {
    e.preventDefault();
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    localStorage.removeItem("Names");
    this.setState({ redirect: true });
  }
  componentDidMount() {
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem(
      "token"
    );
    this.getAdminInfo();
  }
  getAdminInfo = async () => {
    await axios.get(`${this.baseUrl}/admin/profile`).then(res => {
      if (res.data) {
        this.setState({ adminInfo: res.data.data });
      }
    }); 
  };
  render() {
    if (this.state.redirect === true) {
      return <Redirect to={"/"} />
    }
    return (
      <>
        <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
          <Container fluid>
            <Link
              className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
              to="#"
            >
              {this.props.brandText}
            </Link>

            <Nav className="align-items-center d-none d-md-flex" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  {this.state.adminInfo.map(admin => {
                    return(
                      <Media key={admin.id} className="align-items-center">
                        <span className="avatar avatar-sm rounded-circle">
                          <img
                            alt="..."
                            src={require("assets/img/profile/profile.png")}
                          />
                        </span>
                        <Media className="ml-2 d-none d-lg-block">
                          <span className="mb-0 text-sm font-weight-bold">
                            {admin.names}
                          </span>
                        </Media>
                      </Media>
                    )
                  })}
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  
                  <DropdownItem to="/admin/profile" tag={Link}>
                    <i className="ni ni-single-02" />
                    <span>My profile</span>
                  </DropdownItem>
                  <DropdownItem to="/" tag={Link} target="_blank">
                    <i className="fa fa-arrow-right" aria-hidden="true"></i>
                    <span>View as visitor</span>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem 
                    href="/" 
                    onClick={this.logout}
                    >
                    <i className="fa fa-sign-out" aria-hidden="true"></i>
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default AdminNavbar;
