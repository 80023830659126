import Index from "views/admin/Index.js";
import Profile from "views/admin/Profile.js";
import Contacts from "views/admin/Contacts.js";
import Services from "views/admin/Services";
import Initiatives from "views/admin/Initiatives";
import Features from "views/admin/Features";
import ManageService from "views/admin/ManageService";
import ManageInitiative from "views/admin/ManageInitiative";
import ManageFeatures from "views/admin/ManageFeatures";
import AddAdmin from "views/admin/AddAdmin";

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin"
  },
  {
    path: "/profile",
    name: "Profile",
    icon: "ni ni-single-02 text-primary",
    component: Profile,
    layout: "/admin"
  },
  {
    path: "/contacts",
    name: "Contacts",
    icon: "fa fa-envelope text-primary",
    component: Contacts,
    layout: "/admin"
  },
  {
    path: "/add-admin",
    name: "Add an admin",
    icon: "fa fa-minus-square text-primary",
    component: AddAdmin,
    layout: "/admin"
  },
  {
    path: "/services",
    name: "Services",
    icon: "fa fa-minus-square text-primary",
    component: Services,
    layout: "/admin"
  },
  {
    path: "/initiatives",
    name: "Initiatives",
    icon: "fa fa-minus-square text-primary",
    component: Initiatives,
    layout: "/admin"
  },
  {
    path: "/features",
    name: "Features",
    icon: "fa fa-minus-square text-primary",
    component: Features,
    layout: "/admin"
  },
  {
    path: "/manage-services",
    name: "Manage services",
    icon: "fa fa-minus-square text-primary",
    component: ManageService,
    layout: "/admin"
  },
  {
    path: "/manage-initiatives",
    name: "Manage initiatives",
    icon: "fa fa-minus-square text-primary",
    component: ManageInitiative,
    layout: "/admin"
  },
  {
    path: "/manage-features",
    name: "Manage features",
    icon: "fa fa-minus-square text-primary",
    component: ManageFeatures,
    layout: "/admin"
  }
];
export default routes;