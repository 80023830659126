import React from "react";
import tawkTo from "tawkto-react";
import Typical from "react-typical";
import {
  Badge,
  Button,
  Container,
  Row,
  Col,
  UncontrolledCarousel,
  UncontrolledCollapse,
  CardBody,
  Card,
} from "reactstrap";
import { companyInfo } from "../data/companyInfo";
import "assets/scss/am-group.scss?v1.1.0";

import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footers/Footer.js";
import Features from "components/Features/Features.js";
import Reminder from "components/Reminder/Reminder";
import ContactFormTwo from "components/ContactFormTwo/ContactFormTwo";
import FormModal from "components/FormModal/FormModal.js";
import Services from "components/Services/Services";
import Initiatives from "components/Initiatives/Initiatives";

const frontItems = [
  {
    src: require("assets/img/coding.jpeg"),
    altText: "",
    caption: "",
    header: "",
  },
  {
    src: require("assets/img/coding-100.jpg"),
    altText: "",
    caption: "",
    header: "",
  },
];
const steps = [
  "Such as apps",
  1000,
  "Such as expanding the community of developers",
  1000,
  "Such as supporting the growth of upcoming businesses",
  1000,
  "Such as connecting investors to companies in need.",
  1000,
];
const tawkToPropertyId = "5f668b22f0e7167d0011eb72";

class Landing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyInfo: [],
      loading: false,
    };
    this.baseUrl = "https://am-group.herokuapp.com/api/v1";
  }
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;

    this.setState({ loading: true });
    tawkTo(tawkToPropertyId);
  }
  toggleModal = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  setToLocalStorage = (service) => {
    localStorage.setItem("service", JSON.stringify(service.id));
  };
  render() {
    return (
      <>
        <Navbar />
        <main ref="main">
          <div className="position-relative">
            <section className="section section-lg bg-gradient-default pb-250">
              <div className="shape shape-style-1 shape-default"></div>
              <Container className="py-lg-md d-flex">
                <div className="col px-0 pt-5">
                  <Row>
                    <Col lg="6">
                      <h1 className="display-3 text-white">
                        We offer services that offer high-tech tools
                      </h1>
                      <Typical
                        wrapper="p"
                        className="text-success"
                        steps={steps}
                        loop={Infinity}
                      />
                      <p className="lead text-white">
                        We are a pan African firm dedicated to crashing barriers
                        of Africa’s technological progress
                      </p>
                      <div className="btn-wrapper">
                        <Button
                          className="btn-icon mb-3 mb-sm-0"
                          color="success"
                          type="button"
                          onClick={() => this.toggleModal()}
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fa fa-envelope"></i>
                          </span>
                          <span className="btn-inner--text">
                            Interested? contact us
                          </span>
                        </Button>
                        <FormModal
                          isOpen={this.state.isOpen}
                          onHandleOpen={() => this.toggleModal()}
                        />
                      </div>
                    </Col>
                    <Col className="mb-lg-auto" lg="6">
                      <div className="rounded shadow-lg overflow-hidden transform-perspective-right">
                        <UncontrolledCarousel items={frontItems} />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12">
                      <h1 className="display-3 text-white pt-3">
                        We brought to you different services like{" "}
                        <i className="fa fa-hand-o-down" aria-hidden="true"></i>
                      </h1>
                    </Col>
                  </Row>
                </div>
              </Container>
            </section>
          </div>
          <Services />
          <section className="pt-0 section section-lg">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-1" md="6">
                  <div className="pr-md-5">
                    <h3>Why choose to work with us</h3>
                    <p>
                      We are a pan African firm dedicated to crashing barriers
                      of Africa’s technological progress by offering services
                      that offers high-tech tools such as apps, expand the
                      community of developers, support the growth of upcoming
                      businesses, and connect investors to companies in need.
                    </p>
                    <h3>
                      We upskill young and older programmers in different
                      technologies.
                    </h3>
                    <Row>
                      <Col md="6">
                        <ul className="list-unstyled mt-5">
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <Badge
                                  className="badge-circle mr-3"
                                  color="success"
                                >
                                  <i
                                    className="fa fa-check"
                                    aria-hidden="true"
                                  ></i>
                                </Badge>
                              </div>
                              <div>
                                <h6 className="mb-0">Flutter</h6>
                              </div>
                            </div>
                          </li>
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <Badge
                                  className="badge-circle mr-3"
                                  color="success"
                                >
                                  <i
                                    className="fa fa-check"
                                    aria-hidden="true"
                                  ></i>
                                </Badge>
                              </div>
                              <div>
                                <h6 className="mb-0">React native</h6>
                              </div>
                            </div>
                          </li>
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <Badge
                                  className="badge-circle mr-3"
                                  color="success"
                                >
                                  <i
                                    className="fa fa-check"
                                    aria-hidden="true"
                                  ></i>
                                </Badge>
                              </div>
                              <div>
                                <h6 className="mb-0">
                                  Enterprise software development
                                </h6>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </Col>

                      <Col md="6">
                        <ul className="list-unstyled mt-5">
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <Badge
                                  className="badge-circle mr-3"
                                  color="success"
                                >
                                  <i
                                    className="fa fa-check"
                                    aria-hidden="true"
                                  ></i>
                                </Badge>
                              </div>
                              <div>
                                <h6 className="mb-0">IOS</h6>
                              </div>
                            </div>
                          </li>
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <Badge
                                  className="badge-circle mr-3"
                                  color="success"
                                >
                                  <i
                                    className="fa fa-check"
                                    aria-hidden="true"
                                  ></i>
                                </Badge>
                              </div>
                              <div>
                                <h6 className="mb-0">
                                  Windows app development
                                </h6>
                              </div>
                            </div>
                          </li>
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <Badge
                                  className="badge-circle mr-3"
                                  color="success"
                                >
                                  <i
                                    className="fa fa-check"
                                    aria-hidden="true"
                                  ></i>
                                </Badge>
                              </div>
                              <div>
                                <h6 className="mb-0">Android</h6>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <ContactFormTwo />
              </Row>
            </Container>
          </section>
          <Initiatives />
          <section className="section section-lg">
            <Container>
              <Row>
                <Col>
                  <h3>Frequently asked questions </h3>
                  <ul className="list-unstyled mt-3">
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div>
                          <Badge className="badge-circle mr-3" color="success">
                            <i className="fa fa-check" aria-hidden="true"></i>
                          </Badge>
                        </div>
                        <div>
                          <h6
                            className="mb-0"
                            id="togglerOne"
                            style={{ marginBottom: "1rem" }}
                          >
                            What is the purpose of AM Group?
                          </h6>
                        </div>
                      </div>
                      <UncontrolledCollapse toggler="#togglerOne">
                        <Card className="mt-3">
                          <CardBody>
                            We're dedicated to crashing barriers of Africa’s
                            technological progress by offering services that
                            offers high-tech tools such as apps, expand the
                            community of developers, support the growth of
                            upcoming businesses, and connect investors to
                            companies in need.
                          </CardBody>
                        </Card>
                      </UncontrolledCollapse>
                    </li>

                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div>
                          <Badge className="badge-circle mr-3" color="success">
                            <i className="fa fa-check" aria-hidden="true"></i>
                          </Badge>
                        </div>
                        <div>
                          <h6
                            className="mb-0"
                            id="togglerTwo"
                            style={{ marginBottom: "1rem" }}
                          >
                            What are the services you offer?
                          </h6>
                        </div>
                      </div>
                      <UncontrolledCollapse toggler="#togglerTwo">
                        <Card className="mt-3">
                          <CardBody>
                            <p>1. Offshore Outsourcing</p>
                            <p>2. Capacity Building</p>
                            <p>3. Innovation, Startup & Entrepreneurship</p>
                            <p>4. Investment</p>
                          </CardBody>
                        </Card>
                      </UncontrolledCollapse>
                    </li>

                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div>
                          <Badge className="badge-circle mr-3" color="success">
                            <i className="fa fa-check" aria-hidden="true"></i>
                          </Badge>
                        </div>
                        <div>
                          <h6
                            className="mb-0"
                            id="togglerThree"
                            style={{ marginBottom: "1rem" }}
                          >
                            How can I contact you?
                          </h6>
                        </div>
                      </div>
                      <UncontrolledCollapse toggler="#togglerThree">
                        <Card className="mt-3">
                          <CardBody>
                            <h6>
                              Kindly click this button below to contact us
                            </h6>
                            <Button
                              className="btn-neutral btn-icon"
                              color="default"
                              type="button"
                              onClick={() => this.toggleModal()}
                            >
                              <span className="btn-inner--icon">
                                <i className="fa fa-envelope mr-2"></i>
                              </span>
                              <span className="nav-link-inner--text ml-1">
                                Contact us
                              </span>
                            </Button>
                          </CardBody>
                        </Card>
                      </UncontrolledCollapse>
                    </li>
                  </ul>
                </Col>
                {companyInfo.map((info) => {
                  return (
                    <Col key={info.id} lg="6">
                      <h2 className="display-3">About us</h2>
                      <p className="lead text-muted">{info.aboutcompany}</p>
                    </Col>
                  );
                })}
              </Row>
            </Container>
          </section>
          <Reminder />
          <Features />
        </main>
        <Footer />
      </>
    );
  }
}

export default Landing;
