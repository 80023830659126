export const companyInfo = [
  {
    id: 1,
    twitterlink: "https://twitter.com/amutangana",
    instgramlink: "https://www.instagram.com/amutangana/",
    linkdinlink: "https://www.linkedin.com/in/amutangana",
    ceonames: "Aphrodice Mutangana",
    aboutceo:
      "Aphrodice Mutangana is the kLab, biggest IT hub in Rwanda, General Manager and Serial Entrepreneur. He is also a co-initiator of different initiatives namely Future Coders, Incike Initiative, Refugee School of Coding and theWorkshop. Aphrodice is a member of various Board of Directors around the world pertaining to innovation and entrepreneurship.",
    aboutcompany:
      "AM Group is a pan African firm dedicated to crashing barriers of Africa’s technological progress by offering services that offers high-tech tools such as apps, expand the community of developers, support the growth of upcoming businesses, and connect investors to companies in need. ",
    companyname: "AM GROUP",
    location: "Kigali",
  },
];
