import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  Media,
  Table,
  Container,
  Row,
  Button,
  NavLink
} from "reactstrap";
import "assets/scss/am-group-dashboard.scss";
import Header from "components/Headers/Header.js";
import ServiceModal from "components/Modals/ServicesModal";

class Services extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      services: [],
      errorMessage: ""
     }
  this.baseUrl = "https://am-group.herokuapp.com/api/v1";
  }
  componentDidMount() {
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem(
      "token"
    );
    this.getAllServices();
  }
  toggleModal = () => {
    this.setState({
        isOpen: !this.state.isOpen,
    });
  };
  getAllServices = async () => {
    await axios.get(`${this.baseUrl}/services`).then(res => {
      if (res.data) {
        this.setState({ services: res.data.data });
      }
    }); 
  };
  // Delete a services
  deleteService = ({ serviceId, index }, event) => {
    event.preventDefault();

    // Prevent the admin to mistakenly delete a service
    if (!window.confirm("Delete this service?"))
    return;
    axios
      .delete(`${this.baseUrl}/admin/service/${serviceId}`)
      .then(res => {
        this.setState({
          errorMessage: res.data.services
        })
        //Remove from list
        this.setState(state => {
          const services = state.services;

          services.splice(index, 1);
          return { services };
        });
      })
      .catch(error => console.log("Could not delete service"));
  };
  setServiceToLocalStorage = (service) => {
    localStorage.setItem('serviceToUpdate', service.id);
  }
  render() {
    return (
      <>
        <Header />
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <NavLink 
                    to="/admin/manage-services" 
                    tag={Link}>
                    <Button
                      className="mb-0"
                      color="primary"
                      size="sm"
                    >
                      Add service
                    </Button>
                  </NavLink>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Service</th>
                      <th scope="col">Details</th>
                      <th scope="col">Last update</th>
                      <th scope="col">Action</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.services.map(( service, index ) => {
                      return (
                        <tr key={service.id}>
                          <th scope="row">
                            <Media className="align-items-center">
                              <a
                                className="avatar rounded-circle bg-success text-white mr-3"
                                href="#pablo"
                                onClick={e => e.preventDefault()}
                              >
                                {
                                service.id === 1  ? <i className="fa fa-android" aria-hidden="true"></i>:
                                service.id === 2  ? <i className="fa fa-building" aria-hidden="true"></i>:
                                service.id === 3  ? <i className="fa fa-globe" aria-hidden="true"></i>:
                                service.id === 4  ? <i className="fa fa-money" aria-hidden="true"></i>:
                                <i className="fa fa-list" aria-hidden="true"></i> 
                                }
                              </a>
                              <Media>
                                <span className="mb-0 text-sm">
                                  {service.service}
                                </span>
                              </Media>
                            </Media>
                          </th>
                          <td>
                            
                              {service.details.substr(0, 30)}...
                              <Badge 
                                color="primary" 
                                className="badge-dot mr-4"
                                href="#"
                                onClick={() => {
                                  this.toggleModal()
                                  this.setState({srvcId: service.id})
                                }}
                                >
                              <i className="bg-info" />
                                Click for more
                            </Badge>
                            
                          </td>
                          <td>
                            {service.lastupdate}
                          </td>
                          <td>
                            <Badge 
                              color="primary" 
                              className="badge-dot mr-4"
                              href="#"
                              onClick={() => {
                                this.toggleModal()
                                this.setState({srvcId: service.id})
                              }}
                              >
                              <i className="bg-info" />
                                View service
                            </Badge>
                            <ServiceModal 
                                isOpen={this.state.isOpen}
                                srvcId={this.state.srvcId}
                                onHandleOpen={() => this.toggleModal()} />
                            <Badge 
                              color="primary" 
                              className="badge-dot mr-4"
                              onClick={() => this.setServiceToLocalStorage(service)}
                              href={`/admin/manage-services/${service.id}`}
                              >
                              <i className="bg-info" />
                                Update service
                            </Badge>
                            <Badge 
                                color="danger"
                                href="#"
                                onClick={this.deleteService.bind(this, {
                                  serviceId: service.id,
                                  index
                                })}
                                >
                                  Delete this service
                              </Badge>
                            </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
                <CardFooter className="py-4">
                  
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default Services;
