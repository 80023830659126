import React from "react";
import axios from "axios";
import {
  Card,
  CardHeader,
  CardBody,
  Modal
} from "reactstrap";
import "assets/scss/am-group-dashboard.scss";

class MessagesModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            message: []
         }
        this.baseUrl = `https://am-group.herokuapp.com/api/v1`;
    }
    toggleModal = () => {
        this.props.onHandleOpen();
    };

    componentDidUpdate(prevProps, props) {
        if(this.props.msgId !== prevProps.msgId && this.props.isOpen) {
            this.getOneMessage(prevProps.msgId);
        }
    }

    getOneMessage = async () => {
        await axios.get(`${this.baseUrl}/admin/messages/i/${this.props.msgId}`).then(res => {
            if (res.data) {
            this.setState({ message: res.data.data });
            }
        }); 
    };
  render() {
      const { isOpen } = this.props;

    return (
      <>
        <Modal
            className="modal-dialog-centered"
            size="sm"
            isOpen={isOpen}
            toggle={() => this.toggleModal()}
        >
            <div className="modal-body p-0">
                {this.state.message.map(m => {
                    return (
                        <Card className="bg-secondary shadow border-0">
                            <CardHeader className="pb-3">
                            <div className="text-muted text-center mb-3">
                            <small>Sent by {m.sendernames}</small>
                            </div>
                            </CardHeader>
                            <CardBody>
                            <div className="text-center text-muted mb-4">
                                <small>{m.message}</small>
                            </div>
                            
                            </CardBody>
                        </Card>
                    )
                })}
            </div>
        </Modal>
      </>
    );
  }
}

export default MessagesModal;
