/*eslint-disable*/
import React from "react";
import axios from "axios";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Spinner,
} from "reactstrap";

import "assets/scss/am-group.scss?v1.1.0";
import africasalons from "../../assets/img/logos/africasalons.png";
import africatech from "../../assets/img/logos/africatech.png";
import agenceecofin from "../../assets/img/logos/agenceecofin.png";
import bozar from "../../assets/img/logos/bozar.png";
import centralforpublicimpact from "../../assets/img/logos/centralforpublicimpact.png";
import diaftv from "../../assets/img/logos/diaftv.png";
import disruptafrica from "../../assets/img/logos/disruptafrica.png";
import efe from "../../assets/img/logos/efe.png";
import happyinafrica from "../../assets/img/logos/happyinafrica.png";
import igihe from "../../assets/img/logos/igihe.jpg";
import jeuneafrique from "../../assets/img/logos/jeuneafrique.png";
import ktpress from "../../assets/img/logos/ktpress.png";
import lafriquedesidees from "../../assets/img/logos/lafriquedesidees.png";

import latelier from "../../assets/img/logos/latelier.png";
import lepointafrique from "../../assets/img/logos/lepointafrique.jpg";
import lesechos from "../../assets/img/logos/lesechos.png";
import liberation from "../../assets/img/logos/liberation.png";
import linkiesta from "../../assets/img/logos/linkiesta.png";
import mychicafrica from "../../assets/img/logos/mychicafrica.png";
import panoractu from "../../assets/img/logos/panoractu.png";
import rfi from "../../assets/img/logos/rfi.png";
import smh from "../../assets/img/logos/smh.png";
import takepart from "../../assets/img/logos/takepart.png";
import teaaftertwelve from "../../assets/img/logos/teaaftertwelve.png";
import techcentral from "../../assets/img/logos/techcentral.jpg";
import thekigalian from "../../assets/img/logos/thekigalian.png";
import thenewtimes from "../../assets/img/logos/thenewtimes.jpg";
import time from "../../assets/img/logos/time.png";
import Trendbeobachter from "../../assets/img/logos/Trendbeobachter.jpg";
import umuseke from "../../assets/img/logos/umuseke.png";
import washingtonposr from "../../assets/img/logos/washingtonposr.png";
import womeninafrica from "../../assets/img/logos/womeninafrica.png";
import wsj from "../../assets/img/logos/wsj.jpg";
import zeenews from "../../assets/img/logos/zeenews.png";

class Download extends React.Component {
  render() {
    return (
      <>
        <section className="section ">
          <Container fluid>
            <Row className="row-grid">
              <Col className="text-center" lg="12">
                <h2 className="display-3">
                  <span>Featured</span>
                </h2>
                <p className="lead"></p>
              </Col>
            </Row>
            <Row>
              <Col>
                <ul className="owl-carousel list-unstyled">
                  <li>
                    <a
                      href="http://www.africa-salons.com/startups/2017/11/09/aphrodice-mutangana-klab-nous-offrons-des-locaux-lacces-internet-et-un-mentorat-gratuits-aux-jeunes-entrepreneurs/"
                      id="tooltip646643508"
                      target="_blank"
                    >
                      <img alt="..." className="img-fluid" src={africasalons} />
                    </a>
                  </li>

                  <li>
                    <a
                      href="http://www.afrikatech.com/fr/sante/aphrodice-mutangana-le-rwandais-aux-multiples-creations/"
                      id="tooltip646643508"
                      target="_blank"
                    >
                      <img alt="..." className="img-fluid" src={africatech} />
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://www.agenceecofin.com/revue-de-presse/0712-52698-klab-en-passe-de-devenir-le-meilleur-incubateur-du-rwanda"
                      id="tooltip646643508"
                      target="_blank"
                    >
                      <img alt="..." className="img-fluid" src={agenceecofin} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.bozar.be/en/activities/131611-generation-afropreneurs"
                      id="tooltip646643508"
                      target="_blank"
                    >
                      <img alt="..." className="img-fluid" src={bozar} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.centreforpublicimpact.org/driving-growth-rwandas-klab/"
                      id="tooltip646643508"
                      target="_blank"
                    >
                      <img
                        alt="..."
                        className="img-fluid"
                        src={centralforpublicimpact}
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="http://www.diaf-tv.info/les-10-start-up-que-la-france-veut-mettre-a-lhonneur-au-sommet-afrique-france-de-bamako/"
                      id="tooltip646643508"
                      target="_blank"
                    >
                      <img alt="..." className="img-fluid" src={diaftv} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://disrupt-africa.com/2018/05/fintech-cluster-established-at-rwandas-klab/"
                      id="tooltip646643508"
                      target="_blank"
                    >
                      <img
                        alt="..."
                        className="img-fluid"
                        src={disruptafrica}
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.efe.com/efe/english/technology/rwanda-the-country-of-genocides-turns-into-africa-s-technology-paradise/50000267-2632131"
                      id="tooltip646643508"
                      target="_blank"
                    >
                      <img alt="..." className="img-fluid" src={efe} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://happyinafrica.com/reussite/aphrodice-mutangana-fondateur-de-klab-premier-hub-dinnovation-au-rwanda"
                      id="tooltip646643508"
                      target="_blank"
                    >
                      <img
                        alt="..."
                        className="img-fluid"
                        src={happyinafrica}
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://igihe.com/ubukungu/iterambere/article/umunyarwanda-yatangije-urubuga"
                      id="tooltip646643508"
                      target="_blank"
                    >
                      <img alt="..." className="img-fluid" src={igihe} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.jeuneafrique.com/mag/468665/economie/rwanda-hub-des-technologies-de-linformation/"
                      id="tooltip646643508"
                      target="_blank"
                    >
                      <img alt="..." className="img-fluid" src={jeuneafrique} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="http://ktpress.rw/2016/03/survivors-mobile-app-mobilizes-millions-for-widows/"
                      id="tooltip646643508"
                      target="_blank"
                    >
                      <img alt="..." className="img-fluid" src={ktpress} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="http://www.lafriquedesidees.org/aphrodice-mutangana-entrepreneur-social-au-rwanda/"
                      id="tooltip646643508"
                      target="_blank"
                    >
                      <img
                        alt="..."
                        className="img-fluid"
                        src={lafriquedesidees}
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://atelier.bnpparibas/en/health/article/foyo-sms-tv-health-advice-helping-combat-common-illnesses-rwanda"
                      id="tooltip646643508"
                      target="_blank"
                    >
                      <img alt="..." className="img-fluid" src={latelier} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="http://afrique.lepoint.fr/economie/kigali-un-fleuron-high-tech-nomme-klab-06-12-2017-2177588_2258.php"
                      id="tooltip646643508"
                      target="_blank"
                    >
                      <img
                        alt="..."
                        className="img-fluid"
                        src={lepointafrique}
                      />
                    </a>
                  </li>
                  <li>
                    <a href="/" id="tooltip646643508" target="_blank">
                      <img alt="..." className="img-fluid" src={lesechos} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.liberation.fr/futurs/2016/11/06/nouvelles-technologies-le-rwanda-en-plein-declic_1526300"
                      id="tooltip646643508"
                      target="_blank"
                    >
                      <img alt="..." className="img-fluid" src={liberation} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkiesta.it/2017/09/30/ci-aiutiamo-da-soli-grazie-i-millenial-digitali-stanno-cambiando-lafri/"
                      id="tooltip646643508"
                      target="_blank"
                    >
                      <img alt="..." className="img-fluid" src={linkiesta} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://mychicafrica.accorhotels.com/en/cat/startup/klab-the-rwandan-incubator-that-turns-ideas-into-start-ups/"
                      id="tooltip646643508"
                      target="_blank"
                    >
                      <img alt="..." className="img-fluid" src={mychicafrica} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="http://www.panoractu.com/2018/05/22/rwanda-va-devenir-premier-hub-destine-a-technologie-financiere-fin-tech/"
                      id="tooltip646643508"
                      target="_blank"
                    >
                      <img alt="..." className="img-fluid" src={panoractu} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="http://www.rfi.fr/emission/20161029-le-secteur-numerique-africain-pourvoyeur-services-publics"
                      id="tooltip646643508"
                      target="_blank"
                    >
                      <img alt="..." className="img-fluid" src={rfi} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.smh.com.au/world/after-the-genocide-rwanda-looks-to-technology-20140405-zqr6x.html"
                      id="tooltip646643508"
                      target="_blank"
                    >
                      <img alt="..." className="img-fluid" src={smh} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="http://www.takepart.com/video/2014/09/30/silicon-valley-africa"
                      id="tooltip646643508"
                      target="_blank"
                    >
                      <img alt="..." className="img-fluid" src={takepart} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="http://www.tea-after-twelve.com/about-us/authors/aphrodice-muntangana/"
                      id="tooltip646643508"
                      target="_blank"
                    >
                      <img
                        alt="..."
                        className="img-fluid"
                        src={teaaftertwelve}
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://techcentral.co.za/rwanda-in-bid-to-be-africas-hi-tech-hub/52362/"
                      id="tooltip646643508"
                      target="_blank"
                    >
                      <img alt="..." className="img-fluid" src={techcentral} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.kigalian.com/socialmedia/2015/05/10-young-rwandan-entrepreneurs-you-should-follow-on-twitter/"
                      id="tooltip646643508"
                      target="_blank"
                    >
                      <img alt="..." className="img-fluid" src={thekigalian} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.newtimes.co.rw/section/read/108291"
                      id="tooltip646643508"
                      target="_blank"
                    >
                      <img alt="..." className="img-fluid" src={thenewtimes} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="http://time.com/3770968/rwanda-technology-genocide/"
                      id="tooltip646643508"
                      target="_blank"
                    >
                      <img alt="..." className="img-fluid" src={time} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="http://blog.trendbeobachter.de/tag/aphrodice-mutangana/"
                      id="tooltip646643508"
                      target="_blank"
                    >
                      <img
                        alt="..."
                        className="img-fluid"
                        src={Trendbeobachter}
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://umuseke.rw/ikigega-cya-innovation-kigiye-gutangirana-miliyoni-100-zamadolari.html"
                      id="tooltip646643508"
                      target="_blank"
                    >
                      <img alt="..." className="img-fluid" src={umuseke} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.washingtonpost.com/world/africa/20-years-after-the-genocide-rwanda-looks-to-a-tech-revolution/2014/04/04/bbde2df2-bb4a-11e3-80de-2ff8801f27af_story.html?noredirect=on&utm_term=.b4c2f2a54209"
                      id="tooltip646643508"
                      target="_blank"
                    >
                      <img
                        alt="..."
                        className="img-fluid"
                        src={washingtonposr}
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://wia-initiative.com/en/wia-mag-en/dare/k-lab/"
                      id="tooltip646643508"
                      target="_blank"
                    >
                      <img
                        alt="..."
                        className="img-fluid"
                        src={womeninafrica}
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.wsj.com/articles/rwanda-a-poster-child-for-development-with-a-dark-side-1541500201"
                      id="tooltip646643508"
                      target="_blank"
                    >
                      <img alt="..." className="img-fluid" src={wsj} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="http://zeenews.india.com/news/science/rwanda-from-genocides-to-africas-technology-paradise_1608439.html"
                      id="tooltip646643508"
                      target="_blank"
                    >
                      <img alt="..." className="img-fluid" src={zeenews} />
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

export default Download;
