import React, { Component } from "react";
import { Link } from "react-router-dom";
import { initiatives } from "../../data/initiatives";

import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  CardImg,
  NavLink,
} from "reactstrap";
import "assets/scss/am-group.scss?v1.1.0";
import coding from "assets/img/initiatives/coding.jpeg";
import facethegorillas from "assets/img/initiatives/facethegorillas.jpeg";
import fkode from "assets/img/initiatives/fkode.png";
import incikelogo from "assets/img/initiatives/incikelogo.jpg";

class Initiatives extends Component {
  toggleInitiativeModal = () => {
    this.setState({
      isInitiativeModalOpen: !this.state.isInitiativeModalOpen,
    });
  };

  setInitiativeToLocalStorage = (initiative) => {
    localStorage.setItem("initiative", initiative.id);
  };
  render() {
    return (
      <section className="section pb-0 bg-gradient-default">
        <Container fluid>
          <Row>
            <Col lg="4"></Col>
            <Col className="align-content-center" lg="6">
              <h4 className="display-3 text-white pl-5">Initiatives</h4>
              <p className="text-white">
                Initiatives we have been engaged in so far
              </p>
            </Col>
          </Row>
          <Row className="row-grid align-items-center">
            {initiatives.map((initiative) => (
              <Col className="order-lg-1 " lg="3" key={initiative.id}>
                <Card className="shadow shadow-lg--hover mt-5 ">
                  <a
                    href={`/initiative/${initiative.initiative}`}
                    onClick={() => this.setInitiativeToLocalStorage(initiative)}
                  >
                    {initiative.id === 1 ? (
                      <CardImg
                        top
                        width="100%"
                        src={coding}
                        alt={initiative.initiative}
                      />
                    ) : initiative.id === 2 ? (
                      <CardImg
                        top
                        width="100%"
                        src={incikelogo}
                        alt={initiative.initiative}
                      />
                    ) : initiative.id === 3 ? (
                      <CardImg
                        top
                        width="100%"
                        src={facethegorillas}
                        alt={initiative.initiative}
                      />
                    ) : initiative.id === 4 ? (
                      <CardImg
                        top
                        width="100%"
                        src={fkode}
                        alt={initiative.initiative}
                      />
                    ) : (
                      <i className="fa fa-list" aria-hidden="true"></i>
                    )}
                  </a>
                  <CardBody>
                    <div className="d-flex px-3">
                      <div>
                        <NavLink
                          to={`/initiative/${initiative.initiative}`}
                          tag={Link}
                          onClick={() =>
                            this.setInitiativeToLocalStorage(initiative)
                          }
                        >
                          <h5 className="title text-success">
                            {initiative.initiative}
                          </h5>
                        </NavLink>
                        <p>
                          {initiative.id === 3
                            ? `${initiative.details.substr(0, 130)}...`
                            : `${initiative.details.substr(0, 48)}...`}
                        </p>
                        <NavLink
                          to={`/initiative/${initiative.initiative}`}
                          tag={Link}
                          onClick={() =>
                            this.setInitiativeToLocalStorage(initiative)
                          }
                        >
                          <span className="text-success" type="button">
                            Read more{" "}
                            <i
                              className="fa fa-long-arrow-right pl-1"
                              aria-hidden="true"
                            ></i>
                          </span>
                        </NavLink>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    );
  }
}

export default Initiatives;
