import React from "react";
import axios from "axios";
import { Container, Row, Col } from "reactstrap";
import "assets/scss/am-group-dashboard.scss";

class UserHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      adminInfo: [],
     }
  this.baseUrl = "https://am-group.herokuapp.com/api/v1";
  }
  componentDidMount() {
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem(
      "token"
    );
    this.getAdminInfo();
  }
  getAdminInfo = async () => {
    await axios.get(`${this.baseUrl}/admin/profile`).then(res => {
      if (res.data) {
        this.setState({ adminInfo: res.data.data });
      }
    }); 
  };
  render() {
    return (
      <>
        <div
          className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
        >
          <span className="mask bg-gradient-default" />
          
          <Container className="d-flex align-items-center" fluid>
            {this.state.adminInfo.map(admin => {
              return(
                <Row key={admin.id}>
                  <Col lg="12" md="12">
                    <h1 className="display-2 text-white">Dear admin {admin.names}</h1>
                    <p className="text-white mt-0 mb-5">
                      Take a look at your profile and you can make some changes if 
                      you want to.
                    </p>
                  </Col>
                </Row>
              )
            })}
          </Container>
        </div>
      </>
    );
  }
}

export default UserHeader;
