import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  UncontrolledAlert,
  NavLink,
} from "reactstrap";
import "assets/scss/am-group-dashboard.scss";
import HeaderForAll from "components/Headers/HeaderForAll";

class ManageInitiatives extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initiatives: [],
      loading: false,
      isCreating: false,
      errorMessage: "",
      updateErrorMessage: "",
      successMessage: "",
      updateSuccessMessage: "",
    };
    this.baseUrl = "https://am-group.herokuapp.com/api/v1";
  }
  componentDidMount = () => {
    axios.defaults.headers.common["x-auth-token"] =
      localStorage.getItem("token");
    this.setState({ loading: true });
    this.getAllInitiatives();
  };

  getAllInitiatives = async () => {
    await axios
      .get(`${this.baseUrl}/initiatives`)
      .then((res) => {
        if (res.data) {
          this.setState({ initiatives: res.data.data });
        }
        this.setState({ loading: false });
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.log("Svr error!");
      });
  };

  validateInitiative = ({ initiative, details }) => {
    const errors = [];

    if (initiative || initiative === "") {
      if (initiative.length <= 3)
        errors.push(
          "Both initiative and details should be atleast 3 chars long"
        );
    }
    if (details || details === "") {
      if (details.length <= 3)
        errors.push(
          "Both initiative and details should be atleast 3 chars long"
        );
    }
    return { errors };
  };

  storeInitiative = async (event) => {
    event.preventDefault();

    const form = event.target;
    const { initiative, details } = form.elements;

    const { errors } = this.validateInitiative({
      initiative: initiative.value,
      details: details.value,
    });

    if (errors.length) {
      this.setState({ errorMessage: errors[0] });
      return;
    }
    this.setState({ errorMessage: "" });
    this.setState({ isCreating: true });
    axios
      .post(`${this.baseUrl}/admin/initiative`, {
        initiative: initiative.value,
        details: details.value,
      })
      .then((res) => {
        this.setState({ isCreating: false });
        this.setState((state) => {
          const initiatives = state.initiatives;
          initiatives.push(res.data.data);
          return { initiatives };
        });
        this.setState({ successMessage: res.data.successMessage });
        form.reset();
      })
      .catch((error) => {
        this.setState({ errorMessage: "" });
        this.setState({ isCreating: false });
        this.setState({
          errorMessage: "Could not post initiative, try again!",
        });
      });
  };
  // UPDATE

  updateInitiative = async (event) => {
    event.preventDefault();

    const form = event.target;
    const { initiative, details } = form.elements;

    const { errors } = this.validateInitiative({
      initiative: initiative.value,
      details: details.value,
    });

    if (errors.length) {
      this.setState({ updateErrorMessage: errors[0] });
      return;
    }
    this.setState({ updateErrorMessage: "" });
    this.setState({ isCreating: true });
    const clickedInitiative = localStorage.getItem("initiativeToUpdate");
    const parsedId = parseInt(clickedInitiative, 10);
    axios
      .patch(`${this.baseUrl}/admin/initiative/${parsedId}`, {
        initiative: initiative.value,
        details: details.value,
      })
      .then((res) => {
        this.setState({ isCreating: false });
        this.setState((state) => {
          const initiatives = state.initiatives;
          initiatives.push(res.data.data);
          return { initiatives };
        });
        this.setState({ updateSuccessMessage: res.data.message });
        form.reset();
      })
      .catch((error) => {
        this.setState({ updateErrorMessage: "" });
        this.setState({ isCreating: false });
        this.setState({ updateErrorMessage: "Could not update, try again!" });
      });
  };
  render() {
    const clickedInitiative = localStorage.getItem("initiativeToUpdate");
    const parsedId = parseInt(clickedInitiative, 10);
    return (
      <>
        <HeaderForAll />
        <Container className="mt--7" fluid>
          <Row>
            {/* UPDATE SERVICE */}
            <Col className="order-xl-1" lg="6">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col lg="6">
                      <h5 className="mb-0">Update initiative</h5>
                    </Col>
                    <Col lg="6">
                      <NavLink to="/admin/initiatives" tag={Link}>
                        <Button className="mb-0" color="primary" size="sm">
                          View initiatives
                        </Button>
                      </NavLink>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  {this.state.updateSuccessMessage ? (
                    <UncontrolledAlert color="success">
                      <p>{this.state.updateSuccessMessage}</p>
                    </UncontrolledAlert>
                  ) : null}
                  {this.state.initiatives.map((initiative) => {
                    if (initiative.id === parsedId) {
                      return (
                        <Form
                          onSubmit={this.updateInitiative.bind(this)}
                          key={initiative.id}
                        >
                          <div className="pl-lg-4">
                            <Row>
                              <Col lg="12">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-service"
                                  >
                                    Initiative
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    defaultValue={initiative.initiative}
                                    id="input-initiative"
                                    name="initiative"
                                    type="text"
                                  />
                                  <small className="text-danger p-3">
                                    {this.state.updateErrorMessage}
                                  </small>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="12">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-details"
                                  >
                                    Details
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    cols="80"
                                    id="details"
                                    defaultValue={initiative.details}
                                    placeholder="Details..."
                                    rows="10"
                                    type="textarea"
                                  />
                                </FormGroup>
                                <small className="text-danger p-3">
                                  {this.state.updateErrorMessage}
                                </small>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="6">
                                <Button
                                  className="mb-0"
                                  color="success"
                                  type="submit"
                                  size="sm"
                                >
                                  Save changes
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </Form>
                      );
                    }
                    return null;
                  })}
                </CardBody>
              </Card>
            </Col>

            {/* ADD SERVICE */}

            <Col className="order-xl-1" lg="6">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col lg="12">
                      <h5 className="mb-0">Add new initiative</h5>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  {this.state.successMessage ? (
                    <UncontrolledAlert color="success">
                      <p>{this.state.successMessage}</p>
                    </UncontrolledAlert>
                  ) : null}
                  <Form onSubmit={this.storeInitiative.bind(this)}>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-initiative"
                            >
                              Initiative
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-initiative"
                              name="initiative"
                              type="text"
                            />
                            <small className="text-danger p-3">
                              {this.state.errorMessage}
                            </small>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-details"
                            >
                              Details
                            </label>
                            <Input
                              className="form-control-alternative"
                              cols="80"
                              id="details"
                              placeholder="Details..."
                              rows="10"
                              type="textarea"
                            />
                            <small className="text-danger p-3">
                              {this.state.errorMessage}
                            </small>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <Button
                            className="mb-0"
                            color="success"
                            type="submit"
                            size="sm"
                          >
                            Save service
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default ManageInitiatives;
