import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Spinner,
  NavLink
} from "reactstrap";
import "assets/scss/am-group.scss?v1.1.0";
import { Redirect } from "react-router-dom";
import axios from "axios";

class Login extends React.Component {
  constructor() {
    super();

    this.state = {
      email: "",
      pwd: "",
      errorMessage: "",
      loading: false,
      redirectWhenLoggedIn:false,
      redirectToReferrer: false
    };

    this.login = this.login.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;

    if(localStorage.getItem("token")){
      this.setState({redirectWhenLoggedIn:true})
    }
  }

  async login(e) {
    e.preventDefault();

    const user = {
      email: this.state.email,
      pwd: this.state.pwd
    };
    const { email, pwd } = user;
    // validate email and pwd
    if (email && pwd) {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        this.setState({ errorMessage: "Invalid email address, try again!" });
        return;
      }
    } else {
      this.setState({ errorMessage: "Enter both email and password" });
      return;
    }

    this.setState({ loading: true });
    this.setState({ errorMessage: "" });

    const response = await axios
      .post("https://am-group.herokuapp.com/api/v1/admin/auth/login", {
        email,
        pwd
      })
      .catch(error => {
        this.setState({ loading: false });
        if (error.response.status === 401)
          this.setState({ errorMessage: "We don't have this such email or password in our database" });
      });

    if (response) {
      this.setState({ loading: false });
      const token = response.data.data.token;
      localStorage.setItem("Names", response.data.data.loggedInAdmin.names);
      localStorage.setItem("email", response.data.data.loggedInAdmin.email);
      localStorage.setItem("token", token);
      this.setState({ redirectToReferrer: true });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    if (this.state.redirectToReferrer || this.state.redirectWhenLoggedIn) {
      return <Redirect to={"/admin"} />;
    }
    
    return (
      <>
        <main ref="main">
          <section className="section bg-gradient-default section-lg">
            <Container>
              <Row className="justify-content-center">
                <Col lg="5">
                  <Card className="bg-secondary shadow border-0">
                    <CardBody className="px-lg-5 py-lg-5">
                      <div className="text-center text-muted mb-4">
                        <small>Login to AM Group</small>
                      </div>
                      <Form onSubmit={this.login} noValidate role="form">
                        <FormGroup className="mb-3">
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-email-83" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input 
                            placeholder="Your admin email" 
                            type="email"
                            name="email"
                            required
                            onChange={this.onChange} />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-lock-circle-open" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Your password"
                              type="password"
                              autoComplete="off"
                              name="pwd"
                              required
                              onChange={this.onChange}
                            />
                          </InputGroup>
                        </FormGroup>
                        <small className="text-danger p-3">{this.state.errorMessage}</small>
                        <div className="text-center">
                          <Button
                            className="my-4"
                            color="success"
                            type="submit"
                          >
                            Log in
                            {this.state.loading ?(
                              <span className="pl-2">
                                <Spinner size="sm" color="primary" />
                              </span> ) : ( "" )}
                          </Button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                  <Row className="mt-3">
                    <Col xs="6">
                      <NavLink to="/auth/forget-password" tag={Link}>
                            <small>I forgot my password</small>
                      </NavLink>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
      </>
    );
  }
}

export default Login;
