import React from "react";
import {
  Container
} from "reactstrap";
import "assets/scss/am-group-dashboard.scss";
import Header from "components/Headers/Header.js";
import Messages from "components/Messages/Messages";

class Contacts extends React.Component {
  render() {
    return (
      <>
        <Header />
        <Container className="mt--7" fluid>
          <Messages />
        </Container>
      </>
    );
  }
}

export default Contacts;
