import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  UncontrolledAlert,
  NavLink,
} from "reactstrap";
import "assets/scss/am-group-dashboard.scss";
import HeaderForAll from "components/Headers/HeaderForAll";

class ManageFeatures extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      features: [],
      loading: false,
      isCreating: false,
      errorMessage: "",
      updateErrorMessage: "",
      successMessage: "",
      updateSuccessMessage: "",
    };
    this.baseUrl = "https://am-group.herokuapp.com/api/v1";
  }
  componentDidMount = () => {
    axios.defaults.headers.common["x-auth-token"] =
      localStorage.getItem("token");
    this.setState({ loading: true });
    this.getAllfeatures();
  };

  getAllfeatures = async () => {
    await axios
      .get(`${this.baseUrl}/features`)
      .then((res) => {
        if (res.data) {
          this.setState({ features: res.data.data });
        }
        this.setState({ loading: false });
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.log("Svr error!");
      });
  };

  validatefeature = ({ featuredlink }) => {
    const errors = [];

    if (featuredlink.length <= 3 || featuredlink === "") {
      errors.push("That link is not valid");
    }
    return { errors };
  };

  storefeature = async (event) => {
    event.preventDefault();

    const form = event.target;
    const { featuredlink } = form.elements;

    const { errors } = this.validatefeature({
      featuredlink: featuredlink.value,
    });

    if (errors.length) {
      this.setState({ errorMessage: errors[0] });
      return;
    }
    this.setState({ errorMessage: "" });
    this.setState({ isCreating: true });
    axios
      .post(`${this.baseUrl}/admin/features`, {
        featuredlink: featuredlink.value,
      })
      .then((res) => {
        this.setState({ isCreating: false });
        this.setState((state) => {
          const features = state.features;
          features.push(res.data.data);
          return { features };
        });
        this.setState({ successMessage: res.data.successMessage });
        form.reset();
      })
      .catch((error) => {
        this.setState({ errorMessage: "" });
        this.setState({ isCreating: false });
        this.setState({ errorMessage: "Could not post feature, try again!" });
      });
  };
  // UPDATE

  updatefeature = async (event) => {
    event.preventDefault();

    const form = event.target;
    const { featuredlink } = form.elements;

    const { errors } = this.validatefeature({
      featuredlink: featuredlink.value,
    });

    if (errors.length) {
      this.setState({ updateErrorMessage: errors[0] });
      return;
    }
    this.setState({ updateErrorMessage: "" });
    this.setState({ isCreating: true });
    const clickedfeature = localStorage.getItem("featureToUpdate");
    const parsedId = parseInt(clickedfeature, 10);
    axios
      .patch(`${this.baseUrl}/admin/feature/${parsedId}`, {
        featuredlink: featuredlink.value,
      })
      .then((res) => {
        this.setState({ isCreating: false });
        this.setState((state) => {
          const features = state.features;
          features.push(res.data.data);
          return { features };
        });
        this.setState({ updateSuccessMessage: res.data.message });
        form.reset();
      })
      .catch((error) => {
        this.setState({ updateErrorMessage: "" });
        this.setState({ isCreating: false });
        this.setState({ updateErrorMessage: "Could not update, try again!" });
      });
  };
  render() {
    const clickedfeature = localStorage.getItem("featureToUpdate");
    const parsedId = parseInt(clickedfeature, 10);
    return (
      <>
        <HeaderForAll />
        <Container className="mt--7" fluid>
          <Row>
            {/* UPDATE SERVICE */}
            <Col className="order-xl-1" lg="6">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col lg="6">
                      <h5 className="mb-0">Update feature</h5>
                    </Col>
                    <Col lg="6">
                      <NavLink to="/admin/features" tag={Link}>
                        <Button className="mb-0" color="primary" size="sm">
                          View features
                        </Button>
                      </NavLink>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  {this.state.updateSuccessMessage ? (
                    <UncontrolledAlert color="success">
                      <p>{this.state.updateSuccessMessage}</p>
                    </UncontrolledAlert>
                  ) : null}
                  {this.state.features.map((feature) => {
                    if (feature.id === parsedId) {
                      return (
                        <Form
                          onSubmit={this.updatefeature.bind(this)}
                          key={feature.id}
                        >
                          <div className="pl-lg-4">
                            <Row>
                              <Col lg="12">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-featuredlink"
                                  >
                                    Featured link
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    defaultValue={feature.featuredlink}
                                    id="input-feature"
                                    name="featuredlink"
                                    type="text"
                                  />
                                  <small className="text-danger p-3">
                                    {this.state.updateErrorMessage}
                                  </small>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="6">
                                <Button
                                  className="mb-0"
                                  color="success"
                                  type="submit"
                                  size="sm"
                                >
                                  Save changes
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </Form>
                      );
                    }
                    return null;
                  })}
                </CardBody>
              </Card>
            </Col>

            {/* ADD SERVICE */}

            <Col className="order-xl-1" lg="6">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col lg="12">
                      <h5 className="mb-0">Add new feature</h5>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  {this.state.successMessage ? (
                    <UncontrolledAlert color="success">
                      <p>{this.state.successMessage}</p>
                    </UncontrolledAlert>
                  ) : null}
                  <Form onSubmit={this.storefeature.bind(this)}>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-feature"
                            >
                              feature
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-feature"
                              name="featuredlink"
                              type="text"
                            />
                            <small className="text-danger p-3">
                              {this.state.errorMessage}
                            </small>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <Button
                            className="mb-0"
                            color="success"
                            type="submit"
                            size="sm"
                          >
                            Save service
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default ManageFeatures;
