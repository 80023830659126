import React from "react";
import { Link } from "react-router-dom";
import Headroom from "headroom.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { services } from "../../data/services";
import { initiatives } from "../../data/initiatives";
import { companyInfo } from "../../data/companyInfo";
import {
  Button,
  UncontrolledCollapse,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  Row,
  Col,
  NavLink,
} from "reactstrap";
import FormModal from "../FormModal/FormModal";
import "assets/scss/am-group.scss?v1.1.0";
import "./NavBar.css";

class NavbarComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }
  componentDidMount() {
    let headroom = new Headroom(document.getElementById("navbar-main"));

    headroom.init();
  }
  state = {
    collapseClasses: "",
    collapseOpen: false,
  };

  onExiting = () => {
    this.setState({
      collapseClasses: "collapsing-out",
    });
  };

  onExited = () => {
    this.setState({
      collapseClasses: "",
    });
  };

  toggleModal = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  setToLocalStorage = (service) => {
    localStorage.setItem("service", service.id);
  };
  setInitiativeToLocalStorage = (initiative) => {
    localStorage.setItem("initiative", initiative.id);
  };
  render() {
    return (
      <>
        <header className="header-global">
          <Navbar
            className="navbar-main navbar-transparent navbar-light headroom"
            expand="lg"
            id="navbar-main"
          >
            <Container fluid>
              <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
                {/* <img
                  alt="..."
                  src={require("assets/img/logo.png")}
                /> */}
                <h3 className="white-text">AM GROUP</h3>
              </NavbarBrand>
              <button className="navbar-toggler" id="navbar_global">
                <span className="navbar-toggler-icon" />
              </button>
              <UncontrolledCollapse
                toggler="#navbar_global"
                navbar
                className={this.state.collapseClasses}
                onExiting={this.onExiting}
                onExited={this.onExited}
              >
                <div className="navbar-collapse-header">
                  <Row>
                    <Col className="collapse-brand" xs="6">
                      <NavLink to="/" tag={Link}>
                        AM Group
                      </NavLink>
                    </Col>
                    <Col className="collapse-close" xs="6">
                      <button className="navbar-toggler" id="navbar_global">
                        <span />
                        <span />
                      </button>
                    </Col>
                  </Row>
                </div>
                <Nav className="align-items-lg-center " navbar>
                  <NavLink to="/" tag={Link}>
                    Home
                  </NavLink>
                </Nav>
                <Nav className="navbar-nav-hover align-items-lg-center" navbar>
                  <UncontrolledDropdown nav>
                    <DropdownToggle nav>
                      <i
                        className="fa fa-minus-square-o d-lg-none mr-1"
                        aria-hidden="true"
                      ></i>
                      <span className="nav-link-inner--text">Services</span>
                    </DropdownToggle>

                    <DropdownMenu className="dropdown-menu-xl">
                      <div className="dropdown-menu-inner">
                        {services.map((service) => (
                          <Media
                            className="d-flex align-items-center"
                            onClick={() => this.setToLocalStorage(service)}
                            href={`/service/${service.service}`}
                            key={service.id}
                          >
                            <div className="icon icon-shape bg-success rounded-circle text-white">
                              {service.id === 1 ? (
                                <i
                                  className="fa fa-android"
                                  aria-hidden="true"
                                ></i>
                              ) : service.id === 2 ? (
                                <i
                                  className="fa fa-building"
                                  aria-hidden="true"
                                ></i>
                              ) : service.id === 3 ? (
                                <i
                                  className="fa fa-globe"
                                  aria-hidden="true"
                                ></i>
                              ) : service.id === 4 ? (
                                <i
                                  className="fa fa-money"
                                  aria-hidden="true"
                                ></i>
                              ) : (
                                <i
                                  className="fa fa-list"
                                  aria-hidden="true"
                                ></i>
                              )}
                            </div>

                            <Media body className="ml-3">
                              <h6 className="heading text-primary mb-md-1">
                                {service.service}
                              </h6>
                              <p className="description d-none d-md-inline-block mb-0">
                                {service.details.substr(0, 100)}...{" "}
                                <span className="text-success">Read more</span>
                              </p>
                            </Media>
                          </Media>
                        ))}
                      </div>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <UncontrolledDropdown nav>
                    <DropdownToggle nav>
                      <i
                        className="fa fa-minus-square-o d-lg-none mr-1"
                        aria-hidden="true"
                      ></i>
                      <span className="nav-link-inner--text">Initiatives</span>
                    </DropdownToggle>

                    <DropdownMenu className="dropdown-menu-xl">
                      <div className="dropdown-menu-inner">
                        {initiatives.map((initiative) => (
                          <Media
                            className="d-flex align-items-center"
                            onClick={() =>
                              this.setInitiativeToLocalStorage(initiative)
                            }
                            href={`/initiative/${initiative.initiative}`}
                            key={initiative.id}
                          >
                            <Media body className="ml-3">
                              <h6 className="heading text-primary mb-md-1">
                                {initiative.initiative}
                              </h6>
                              <p className="description d-none d-md-inline-block mb-0">
                                {initiative.details.substr(0, 100)}...{" "}
                                <span className="text-success">Read more</span>
                              </p>
                            </Media>
                          </Media>
                        ))}
                      </div>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </Nav>

                <Nav className="align-items-lg-center " navbar>
                  <NavLink to="/about-us" tag={Link}>
                    About AM Group
                  </NavLink>
                </Nav>

                <Nav className="navbar-nav-hover align-items-lg-center" navbar>
                  <UncontrolledDropdown nav>
                    <DropdownToggle nav>
                      <i
                        className="fa fa-minus-square-o d-lg-none mr-1"
                        aria-hidden="true"
                      ></i>
                      <span className="nav-link-inner--text">
                        We're on social media
                      </span>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-lg">
                      <div className="dropdown-menu-inner">
                        {companyInfo.map((info) => (
                          <div key={info.id}>
                            <Media
                              className="d-flex align-items-center"
                              href={info.twitterlink}
                              target="_blank"
                            >
                              <div className="icon icon-shape bg-primary rounded-circle text-white">
                                <i className="fa fa-twitter" />
                              </div>
                              <Media body className="ml-3">
                                <h6 className="heading text-primary mb-md-1">
                                  We're on twitter
                                </h6>
                                <p className="description d-none d-md-inline-block mb-0">
                                  Follow us on twitter
                                </p>
                              </Media>
                            </Media>

                            <Media
                              className="d-flex align-items-center"
                              href={info.instgramlink}
                              target="_blank"
                            >
                              <div className="icon icon-shape bg-warning rounded-circle text-white">
                                <i className="fa fa-instagram" />
                              </div>
                              <Media body className="ml-3">
                                <h6 className="heading text-primary mb-md-1">
                                  We're on instagram
                                </h6>
                                <p className="description d-none d-md-inline-block mb-0">
                                  Follow us on instagram
                                </p>
                              </Media>
                            </Media>

                            <Media
                              className="d-flex align-items-center"
                              href={info.linkdinlink}
                              target="_blank"
                            >
                              <div className="icon icon-shape bg-primary rounded-circle text-white">
                                <i className="fa fa-linkedin" />
                              </div>
                              <Media body className="ml-3">
                                <h6 className="heading text-primary mb-md-1">
                                  We're on Linkedin
                                </h6>
                                <p className="description d-none d-md-inline-block mb-0">
                                  Connect with us on Linkedin
                                </p>
                              </Media>
                            </Media>
                          </div>
                        ))}
                      </div>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </Nav>

                <Nav className="align-items-lg-center ml-lg-auto" navbar>
                  <NavItem className="d-none d-lg-block ml-lg-4">
                    <Button
                      className="btn-neutral btn-icon"
                      color="default"
                      type="button"
                      onClick={() => this.toggleModal()}
                    >
                      <span className="btn-inner--icon">
                        <i className="fa fa-envelope mr-2"></i>
                      </span>
                      <span className="nav-link-inner--text ml-1">
                        Get in touch
                      </span>
                    </Button>
                    <FormModal
                      isOpen={this.state.isOpen}
                      onHandleOpen={() => this.toggleModal()}
                    />
                  </NavItem>
                </Nav>
              </UncontrolledCollapse>
            </Container>
          </Navbar>
          <ToastContainer />
        </header>
      </>
    );
  }
}

export default NavbarComponent;
