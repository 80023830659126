export const initiatives = [
  {
    id: 1,
    initiative: "FUTURE CODERS",
    subtitle: "Where coding begins",
    details:
      "Future coders is a program of equipping young students with extra knowledge outside school work in coding/programming. Through this program, the students aged between 8-15 are introduced in the fundamentals of coding as well as guided to put into practice those acquired skills. The curriculum consists of 7 different coding languages that include HTML, CSS, Javascript, Python, PHP, Java and scratch. The long-term vision is to introduce them in Java, Javascript, C#, Python, C++, C, PHP, Ruby, Go, Perl, PL/SQL, SCALA, Objective-C, APEX, R, SWIFT, SAS, Matlab, Crystal, Scratch.",
    image: "",
  },
  {
    id: 2,
    initiative: "INCIKE INITIATIVES",
    subtitle: "You are not alone",
    details:
      "Incike initiative, a crowdfunding mobile app (USSD and SMS based platform) used to raise funds to help elderly survivors of the 1994 Genocide against Tutsi. Incike are elderly aged above 70 years and survived alone. Incike initiative was 100-days initiative.",
    image: "",
  },
  {
    id: 3,
    initiative: "SCHOOL OF CODING",
    subtitle: "school of coding.",
    details:
      "In partnership with The American Refugee Committee, This program aims to provide a unique, extensive program that will help equip and groom talented creative refugee programmers to provide them with necessary tools needed to make them into ‘A level skill programmers and developers’ in Rwanda. Programs taught include Java, Javascript, C#, Python, C++, C, PHP, Ruby, Go, Perl, PL/SQL, SCALA, Objective-C, APEX, R, SWIFT, SAS, Matlab, Crystal, Scratch. Unlike other programs, the uniqueness and end goal for this program is that selected trainees will be provided with the help needed for them to apply and get jobs.",
    image: "",
  },
];
