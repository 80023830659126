import React, { Component } from "react";
import axios from "axios";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  UncontrolledAlert,
  Spinner,
  Form,
} from "reactstrap";
import "assets/scss/am-group.scss?v1.1.0";

class ContactFormTwo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      namesErrorMessage: "",
      emailErrorMessage: "",
      phoneErrorMessage: "",
      messageErrorMessage: "",
      messages: [],
      loading: false,
    };
    this.baseUrl = `https://am-group.herokuapp.com/api/v1`;
  }
  // INSERTING A MESSAGE

  storeMessage = async (event) => {
    event.preventDefault();
    const form = event.target;
    const { sendernames, senderemail, senderphone, message } = form.elements;

    if (sendernames.value.length === 0) {
      this.setState({ namesErrorMessage: "Tell us your name please" });
    } else {
      this.setState({ namesErrorMessage: "" });
    }
    if (senderemail.value.length === 0) {
      this.setState({ emailErrorMessage: "Your email address please" });
    } else {
      this.setState({ emailErrorMessage: "" });
    }
    if (senderphone.value.length === 0) {
      this.setState({
        phoneErrorMessage: "Your phone so that we can get right back to you",
      });
    } else {
      this.setState({ phoneErrorMessage: "" });
    }
    if (message.value.length === 0) {
      this.setState({ messageErrorMessage: "Tell us something!" });
    } else {
      this.setState({ messageErrorMessage: "" });
    }
    this.setState({ loading: true });
    axios
      .post(`${this.baseUrl}/contact`, {
        sendernames: sendernames.value,
        senderemail: senderemail.value,
        senderphone: senderphone.value,
        message: message.value,
      })
      .then((res) => {
        this.setState({ isCreating: false });
        this.setState((state) => {
          const messages = state.messages;
          messages.push(res.data.data);
          return { messages };
        });
        console.log(res.data.message);
        form.reset();
      })
      .catch((error) => {
        this.setState({ loading: false });
        this.setState({
          errorMessage: "Could not send your message please try again",
        });
      });
    this.setState({
      errorMessage:
        "We have received your message and we will get back to you soon",
    });
    this.setState({ loading: false });
  };
  render() {
    let color = "";
    if (
      this.state.errorMessage === "Could not send your message please try again"
    ) {
      color = "danger";
    } else {
      color = "success";
    }
    return (
      <Col className="order-md-2" md="6">
        <div className="">
          <Card className="bg-gradient-secondary shadow">
            <CardBody className="p-lg-5">
              <h4 className="mb-1">Interested in what we offer?</h4>
              <p className="mt-0">
                If you are interested in what we offer, kindly send us a
                message.
              </p>
              {this.state.errorMessage ? (
                <UncontrolledAlert color={color}>
                  <p>{this.state.errorMessage}</p>
                </UncontrolledAlert>
              ) : null}
              <Form onSubmit={this.storeMessage.bind(this)} role="form">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fa fa-user" aria-hidden="true"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Your names"
                      id="sendernames"
                      type="text"
                    />
                  </InputGroup>
                  {this.state.namesErrorMessage ? (
                    <small className="text-danger m-3">
                      {this.state.namesErrorMessage}
                    </small>
                  ) : null}
                </FormGroup>
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fa fa-envelope" aria-hidden="true"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Email" id="senderemail" type="email" />
                  </InputGroup>
                  {this.state.emailErrorMessage ? (
                    <small className="text-danger m-3">
                      {this.state.emailErrorMessage}
                    </small>
                  ) : null}
                </FormGroup>

                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fa fa-mobile" aria-hidden="true"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Your phone number"
                      type="phone"
                      id="senderphone"
                      autoComplete="off"
                    />
                  </InputGroup>
                  {this.state.phoneErrorMessage ? (
                    <small className="text-danger m-3">
                      {this.state.phoneErrorMessage}
                    </small>
                  ) : null}
                </FormGroup>
                <FormGroup className="mb-4">
                  <Input
                    cols="80"
                    id="message"
                    placeholder="Type a message here..."
                    rows="4"
                    type="textarea"
                  />
                </FormGroup>
                {this.state.messageErrorMessage ? (
                  <small className="text-danger m-3">
                    {this.state.messageErrorMessage}
                  </small>
                ) : null}
                <div className="text-center">
                  <Button className="my-4" color="success" type="submit">
                    Send your message
                    {this.state.loading ? (
                      <span className="pl-2">
                        <Spinner size="sm" color="primary" />
                      </span>
                    ) : (
                      ""
                    )}
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Col>
    );
  }
}

export default ContactFormTwo;
