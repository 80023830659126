import React from "react";
import { Container } from "reactstrap";
import "assets/scss/am-group-dashboard.scss";

class HeaderForAll extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    return (
      <>
        <div
          className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
        >
          <span className="mask bg-gradient-default" />
          <Container className="d-flex align-items-center" fluid> 
          </Container>
        </div>
      </>
    );
  }
}

export default HeaderForAll;
