import React from "react";
import axios from "axios";
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Button,
  Col
} from "reactstrap";
import "assets/scss/am-group-dashboard.scss";
import Header from "components/Headers/Header.js";
import FeaturesModal from "components/Modals/FeatureModal";

class Features extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      features: [],
      image: null,
      errorMessage: ""
     }
  this.baseUrl = "https://am-group.herokuapp.com/api/v1";
  }
  componentDidMount() {
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem(
      "token"
    );
    this.getAllFeatures();
  }
  getAllFeatures = async () => {
    await axios.get(`${this.baseUrl}/features`).then(res => {
      if (res.data) {
        this.setState({ features: res.data.data });
      }
    }); 
  };

  deleteFeature = ({ featureId, index }, event) => {
    event.preventDefault();
    if (!window.confirm("Delete this feature?"))
    return;
    axios
      .delete(`${this.baseUrl}/admin/feature/${featureId}`)
      .then(res => {
        this.setState({
          errorMessage: res.data.message
        })
        //Remove from list
        this.setState(state => {
          const features = state.features;

          features.splice(index, 1);
          return { features };
        });
      })
      .catch(error => console.log("Could not delete feature"));
  };

  // Delete all features
  deleteAllFeatures = (event) => {
    event.preventDefault();
    if (!window.confirm("Delete all of features?"))
    return;
    axios
      .delete(`${this.baseUrl}/admin/features`)
      .then(res => {
        this.setState({
          errorMessage: res.data.message
        })
        this.setState(state => {
          const features = state.features;
          return { features };
        });
      })
      .catch(error => console.log("Could not delete features"));
  };
  uploadFeatureImage = ({ featureId, index }, event) => {
    event.preventDefault();
    let formData = new FormData();
    formData.feature = formData.append('feature', this.state.image);
    if(! this.state.image) return console.error('No image selected');
    axios
      .patch(`${this.baseUrl}/admin/feature/upload/${featureId}`, formData)
      .then(res => {
        console.log('in frontend', res.data);
        if(res.data.status === 200) {
        this.setState({
          features: res.data.feature
        });
        }
        this.setState({
          errorMessage: res.data.error || res.data.message
        })
      })
      .catch(error => console.log("Could not upload image"));
  };
  toggleModal = () => {
    this.setState({
        isOpen: !this.state.isOpen,
    });
  };
  setFeatureToLocalStorage = (feature) => {
    localStorage.setItem('featureToUpdate', feature.id);
  }
  render() {
    return (
      <>
        <Header />
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row>
                    <Col lg="2">
                      <Button
                        className="mb-0"
                        color="primary"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        size="sm"
                        >
                        Add Feature
                      </Button>
                    </Col>
                    <Col lg="2">
                      <Button
                          className="mb-0"
                          color="danger"
                          href="#pablo"
                          onClick={this.deleteAllFeatures.bind(this)}
                          size="sm"
                          >
                          Delete all features
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Link</th>
                      <th scope="col">Logo</th>
                      <th scope="col">Last update</th>
                      <th scope="col">Actions</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.features.map((feature, index ) => {
                      return (
                        <tr key={feature.id}>
                          <td>
                            {feature.featuredlink.substr(0, 80)}
                            <Badge 
                                color="primary" 
                                className="badge-dot mr-4"
                                href="#"
                                onClick={() => {
                                  this.toggleModal()
                                  this.setState({ftId: feature.id})
                                }}
                                >
                              <i className="bg-info" />
                                Click to view full link
                            </Badge>
                            <FeaturesModal 
                                isOpen={this.state.isOpen}
                                ftId={this.state.ftId}
                                onHandleOpen={() => this.toggleModal()} />
                          </td>
                          <td>
                            <div className="avatar-group">
                              <a
                                className="avatar avatar-sm"
                                href="#pablo"
                                onClick={e => e.preventDefault()}
                              >
                                <img
                                  alt="..."
                                  className="rounded-circle"
                                  src={feature.image}
                                />
                              </a>
                                <input 
                                  type="file"
                                  color="primary"
                                  accept="image/*"
                                  id={feature.id}
                                  onChange={(e) => this.setState({image: e.target.files[0]})}
                                />
                                <Badge 
                                  color="primary" 
                                  className="badge-dot"
                                  href="#"
                                  onClick={this.uploadFeatureImage.bind(this, {
                                    featureId: feature.id,
                                    index
                                  })}
                                  >
                                  <i className="bg-info" />
                                    upload logo
                                </Badge>
                            </div>
                          </td>
                          <td>
                            {feature.lastupdate}
                          </td>
                          <td>
                            <Badge 
                              color="primary" 
                              className="badge-dot mr-4"
                              onClick={() => this.setFeatureToLocalStorage(feature)}
                              href={`/admin/manage-features/${feature.id}`}
                              >
                              <i className="bg-info" />
                                Update feature
                            </Badge>
                            <Badge 
                                color="danger"
                                href="#"
                                onClick={this.deleteFeature.bind(this, {
                                  featureId: feature.id,
                                  index
                                })}
                                >
                                  Delete this feature
                              </Badge>
                            </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
                <CardFooter className="py-4">
                  
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default Features;
