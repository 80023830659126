import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Spinner,
  NavLink
} from "reactstrap";
import "assets/scss/am-group.scss?v1.1.0";
import axios from "axios";

class ResetPassword extends React.Component {
  constructor() {
    super();

    this.state = {
        pwd: "",
        confirmpwd: "",
      errorMessage: "",
      loading: false
    };

    this.resetPassword = this.resetPassword.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  async resetPassword(e) {
    e.preventDefault();

    const user = {
      pwd: this.state.pwd,
      confirmpwd: this.state.confirmpwd
    };
    const { pwd, confirmpwd } = user;
    // validate email
    if (pwd || confirmpwd < 3) {
        this.setState({ errorMessage: "Strong password please" });
    } else {
      this.setState({ errorMessage: "Type in your password" });
      return;
    }
    if (pwd !== confirmpwd) {
        this.setState({errorMessage: "Passwords do not match"})
        return;
    }
    this.setState({ loading: true });
    this.setState({ errorMessage: "" });

     await axios
      .put("https://am-group.herokuapp.com/api/v1/admin/auth/reset/tunezepatrick@gmail.com/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InR1bmV6ZXBhdHJpY2tAZ21haWwuY29tIiwiaWF0IjoxNTk4ODc1NjI1LCJleHAiOjE1OTg5NjIwMjV9.DE575ER1Qg3ewaFie5Qqn7_PHN19qggQXgQpTvVoCcQ", {
        pwd, confirmpwd
      })
      .then(res => {
        if (res.data) {
          this.setState({ link: res.data.data });
          this.setState({errorMessage: "Password updated successfully"})
        }
        this.setState({ loading: false });
      })
      .catch(error => {
        this.setState({ loading: false });
        if (error.response.status === 404)
          this.setState({ errorMessage: "Something went wrong" });
      });
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
      let color = "text-danger p-3";
      if (this.state.errorMessage === "Password updated successfully") color = "text-success p-3"
    return (
      <>
        <main ref="main">
          <section className="section bg-gradient-default section-lg">
            <Container>
              <Row className="justify-content-center">
                <Col lg="5">
                  <Card className="bg-secondary shadow border-0">
                    <CardBody className="px-lg-5 py-lg-5">
                      <div className="text-center text-muted mb-4">
                        <small>Enter your new password</small>
                      </div>
                      <Form onSubmit={this.resetPassword} noValidate role="form">
                        <FormGroup className="mb-3">
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-email-83" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input 
                            placeholder="Your password" 
                            type="password"
                            name="pwd"
                            required
                            onChange={this.onChange} />
                          </InputGroup>
                        </FormGroup>

                        <FormGroup className="mb-3">
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-password-83" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input 
                            placeholder="Confirm it" 
                            type="password"
                            name="confirmpwd"
                            required
                            onChange={this.onChange} />
                          </InputGroup>
                        </FormGroup>
                        
                        <small className={color}>{this.state.errorMessage}</small>
                        <div className="text-center">
                          <Button
                            className="my-4"
                            color="success"
                            type="submit"
                          >
                            Reset
                            {this.state.loading ?(
                              <span className="pl-2">
                                <Spinner size="sm" color="primary" />
                              </span> ) : ( "" )}
                          </Button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                  <Row className="mt-3">
                    <Col xs="6">
                        <NavLink to="/auth/am-group-admin" tag={Link}>
                            <small>I remember my password</small>
                        </NavLink>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
      </>
    );
  }
}

export default ResetPassword;
