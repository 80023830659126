import React, { Component } from "react";
import { services } from "../../data/services";
import axios from "axios";
import { Button, Card, CardBody, Container, Row, Col } from "reactstrap";
import "assets/scss/am-group.scss?v1.1.0";

class Services extends Component {
  constructor(props) {
    super(props);
    this.state = {
      services: [],
    };
    this.baseUrl = "https://am-group.herokuapp.com/api/v1";
  }
  componentDidMount() {
    // API
    this.getAllServices();
    this.setState({ loading: true });
  }
  getAllServices = async () => {
    await axios
      .get(`${this.baseUrl}/services`)
      .then((res) => {
        if (res.data) {
          this.setState({ services: res.data.data });
        }
        this.setState({ loading: false });
      })
      .catch((error) => {
        this.setState({ loading: false });
        return;
      });
  };
  setToLocalStorage = (service) => {
    localStorage.setItem("service", service.id);
  };
  render() {
    return (
      <section className="section section-lg pt-lg-0 mt--200">
        <Container fluid>
          <Row className="justify-content-center">
            <Col lg="12">
              <Row className="row-grid">
                {services.map((service) => (
                  <Col lg="3" key={service.id}>
                    <Card className="card-lift--hover shadow border-0">
                      <CardBody>
                        <a
                          onClick={() => this.setToLocalStorage(service)}
                          href={`/service/${service.service}`}
                        >
                          <h6 className="text-success text-uppercase">
                            {service.service}
                          </h6>
                        </a>
                        <p className="description mt-3">
                          {service.details.substr(0, 150)}...
                        </p>
                        <Button
                          className="mt-4"
                          color="success"
                          size="sm"
                          onClick={() => this.setToLocalStorage(service)}
                          href={`/service/${service.service}`}
                        >
                          Learn more
                        </Button>
                        {service.id === 2 ? (
                          <Button
                            className="mt-4"
                            color="primary"
                            size="sm"
                            target="_blank"
                            href="https://coder.africa/"
                          >
                            Visit the website
                            <i
                              className="fa fa-long-arrow-right pl-1"
                              aria-hidden="true"
                            ></i>
                          </Button>
                        ) : (
                          <Button
                            className="mt-4"
                            color="primary"
                            size="sm"
                            target="_blank"
                            href="#"
                          >
                            Visit the website
                            <i
                              className="fa fa-long-arrow-right pl-1"
                              aria-hidden="true"
                            ></i>
                          </Button>
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

export default Services;
