import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";
import "assets/scss/am-group-dashboard.scss";
import UserHeader from "components/Headers/UserHeader.js";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      adminInfo: [],
     }
  this.baseUrl = "https://am-group.herokuapp.com/api/v1";
  }
  componentDidMount() {
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem(
      "token"
    );
    this.getAdminInfo();
  }
  getAdminInfo = async () => {
    await axios.get(`${this.baseUrl}/admin/profile`).then(res => {
      if (res.data) {
        this.setState({ adminInfo: res.data.data });
      }
    }); 
  };
  render() {
    return (
      <>
        <UserHeader />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
              <Card className="card-profile shadow">
                <Row className="justify-content-center">
                  <Col className="order-lg-2" lg="3">
                    <div className="card-profile-image">
                      <a href="#pablo" onClick={e => e.preventDefault()}>
                        <img
                          alt="..."
                          className="rounded-circle"
                          src={require("assets/img/profile/profile.png")}
                        />
                      </a>
                    </div>
                  </Col>
                </Row>
                <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                  <div className="d-flex justify-content-between">
                    <Link to="/admin" tag={Link}>
                      <Button
                          className="mr-4"
                          color="info"
                          size="sm"
                        >
                          Dashboard
                        </Button>
                    </Link>
                      
                    <Link to="/admin/contacts" tag={Link}>
                      <Button
                          className="mr-4"
                          color="info"
                          size="sm"
                        >
                          Messages
                        </Button>
                    </Link>
                  </div>
                </CardHeader>
                <CardBody className="pt-2 pt-md-4">
                  {this.state.adminInfo.map(admin => {
                    return (
                      <div key={admin.id} className="text-center mt-md-5">
                        <h3>
                          {admin.names}
                        </h3>
                        <div className="h5 font-weight-300">
                          {admin.email}
                        </div>
                        <hr className="my-4" />
                        <p>
                          Hey {admin.names} you have been an admin of AM Group since {admin.created_on}, remember?
                        </p>
                      </div>
                    )
                  })}
                </CardBody>
              </Card>
            </Col>
            <Col className="order-xl-1" xl="8">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col lg="12">
                      <h3 className="mb-0">Update account</h3>
                    </Col>
                   
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      Admin information
                    </h6>
                    <div className="pl-lg-4">
                      {this.state.adminInfo.map(admin => {
                        return (
                          <Row key={admin.id}>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-username"
                                >
                                  Names
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  defaultValue={admin.names}
                                  id="input-username"
                                  type="text"
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-email"
                                >
                                  Email address
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  disabled
                                  defaultValue={admin.email}
                                  type="email"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        )
                      })}
                      <Row>
                        <Col lg="6">
                          <Button
                            className="mb-0"
                            color="success"
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                            size="sm"
                            >
                            Save changes
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Profile;
