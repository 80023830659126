export const services = [
  {
    id: 1,
    service: "Offshore Outsourcing",
    details:
      "This is a product namely Test Africa mainly focused on mobile and web app development, custom software development, front-end and back-end development, flutter, react native, android, IOS, windows app development, enterprise software development, and emerging technologies development and consultancy.",
  },
  {
    id: 2,
    service: "Capacity Building",
    details:
      "This is a program aiming at preparing the next generation of 6 years and older developers through trainings to introduce young people to programming skills and create a specialized pool of “A level” developers by upskilling already existing programmers in different languages.",
  },
  {
    id: 3,
    service: "Innovation, Startup & Entrepreneurship",
    details:
      "This is a customized entrepreneurship ecosystem support program whose main goal is attracting start-ups, investment funds, and academia to scale up companies in various African countries .",
  },
  {
    id: 4,
    service: "Investment",
    details:
      "This program aims at connecting investors or donors to institutions, organizations, companies, startups or individuals seeking for investments, loans, or donations. This service primarily targets governments, banks, existing businesses, and start-ups.",
  },
];
