import React from "react";
import tawkTo from "tawkto-react";
import { Container, Row, Col } from "reactstrap";
import "assets/scss/am-group.scss?v1.1.0";

import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footers/Footer.js";
import Features from "components/Features/Features.js";
import Reminder from "components/Reminder/Reminder";
import ContactFormTwo from "components/ContactFormTwo/ContactFormTwo";
import Services from "components/Services/Services";
import { services } from "../data/services";

const tawkToPropertyId = "5f668b22f0e7167d0011eb72";

class Service extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
    tawkTo(tawkToPropertyId);
  }

  render() {
    const clickedService = localStorage.getItem("service");
    const parsedId = parseInt(clickedService, 10);
    return (
      <>
        <Navbar />
        <main ref="main">
          <div className="position-relative">
            <section className="section section-lg bg-gradient-default pb-250">
              <div className="shape shape-style-1 shape-default"></div>
              <Container fluid className="py-lg-md d-flex">
                <div className="col px-0 pt-5 pl-md-5 pl-lg-5">
                  <Row>
                    <Col lg="5">
                      {services.map((service) => {
                        if (service.id === parsedId) {
                          return (
                            <div key={service.id}>
                              <h1 className="display-3 text-white">
                                {service.service}
                              </h1>
                              <p className="lead text-white">
                                {service.details}
                              </p>
                            </div>
                          );
                        }
                        return null;
                      })}
                      <h1 className="display-3 text-white pt-3">
                        All services we offer{" "}
                        <i className="fa fa-hand-o-down" aria-hidden="true"></i>
                      </h1>
                    </Col>
                    <ContactFormTwo />
                  </Row>
                </div>
              </Container>
            </section>
          </div>
          <Services />
          <Reminder />

          <Features />
        </main>
        <Footer />
      </>
    );
  }
}

export default Service;
