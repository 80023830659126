/*eslint-disable*/
import React from "react";
// reactstrap components
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import axios from "axios";
import "assets/scss/am-group.scss?v1.1.0";
import FormModal from "components/FormModal/FormModal";
import { companyInfo } from "../../data/companyInfo";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyInfo: [],
    };
    this.baseUrl = "https://am-group.herokuapp.com/api/v1";
  }
  componentDidMount() {
    // APIs
    this.getCompanyInfo();
  }
  getCompanyInfo = async () => {
    await axios.get(`${this.baseUrl}/amcompany`).then((res) => {
      if (res.data) {
        this.setState({ companyInfo: res.data.data });
      }
    });
  };
  toggleModal = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };
  render() {
    return (
      <>
        <footer className=" footer bg-gradient-default">
          <Container fluid className="pl-md-5 pl-lg-5">
            <Row className="row-grid align-items-center mb-5">
              <Col lg="9">
                <h3 className=" text-white font-weight-light mb-2">
                  We are on social media
                </h3>
                <h5 className="text-white mb-0 font-weight-light">
                  You can reach out to us on social media or simply send us a
                  message, when you send us a message we get back to you right
                  away
                </h5>
              </Col>
              <Col className="text-lg-center btn-wrapper" lg="3">
                {companyInfo.map((info) => {
                  return (
                    <div key={info.id}>
                      <Button
                        className="btn-icon-only rounded-circle"
                        color="twitter"
                        href={info.twitterlink}
                        id="tooltip475038074"
                        target="_blank"
                      >
                        <span className="btn-inner--icon">
                          <i className="fa fa-twitter" />
                        </span>
                      </Button>
                      <UncontrolledTooltip delay={0} target="tooltip475038074">
                        Follow us
                      </UncontrolledTooltip>

                      <Button
                        className="btn-icon-only rounded-circle ml-1"
                        color="danger"
                        href={info.instgramlink}
                        id="tooltip837440414"
                        target="_blank"
                      >
                        <span className="btn-inner--icon">
                          <i className="fa fa-instagram" />
                        </span>
                      </Button>
                      <UncontrolledTooltip delay={0} target="tooltip837440414">
                        Follow us
                      </UncontrolledTooltip>
                      <Button
                        className="btn-icon-only rounded-circle ml-1"
                        color="primary"
                        href={info.linkdinlink}
                        id="tooltip829810202"
                        target="_blank"
                      >
                        <span className="btn-inner--icon">
                          <i className="fa fa-linkedin" />
                        </span>
                      </Button>
                      <UncontrolledTooltip delay={0} target="tooltip829810202">
                        Connect
                      </UncontrolledTooltip>
                      <Button
                        className="btn-icon-only rounded-circle ml-1"
                        color="success"
                        id="tooltip829810201"
                        type="button"
                        onClick={() => this.toggleModal()}
                      >
                        <span className="btn-inner--icon">
                          <i className="fa fa-envelope" aria-hidden="true"></i>
                        </span>
                      </Button>
                      <FormModal
                        isOpen={this.state.isOpen}
                        onHandleOpen={() => this.toggleModal()}
                      />
                      <UncontrolledTooltip delay={0} target="tooltip829810201">
                        Send us a message
                      </UncontrolledTooltip>
                    </div>
                  );
                })}
              </Col>
            </Row>
            <hr />
            <Row className="text-white align-items-center justify-content-md-between">
              <Col md="6">
                <div className=" copyright">
                  Copyright AM Group {new Date().getFullYear()}{" "}
                </div>
              </Col>
              <Col md="6">
                <Nav className=" nav-footer justify-content-end">
                  <NavItem>
                    <NavLink type="button" onClick={() => this.toggleModal()}>
                      Contact us
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="/about-us">About AM Group</NavLink>
                  </NavItem>
                </Nav>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    );
  }
}

export default Footer;
