import React, { Component } from 'react';
import axios from "axios";
import {
  Card,
  CardHeader,
  CardFooter,
  Badge,
  Table,
  Row,
  Col
} from "reactstrap";
import "assets/scss/am-group-dashboard.scss";
import MessageModal from "components/Modals/MessagesModal";

class Messages extends Component {
    constructor(props) {
        super(props);
        this.state = { 
          msgId: '',
          messages: [],
          errorMessage: ""
         }
      this.baseUrl = "https://am-group.herokuapp.com/api/v1";
      }
      componentDidMount() {
        axios.defaults.headers.common["x-auth-token"] = localStorage.getItem(
          "token"
        );
        this.getAllMessages();
      }
      toggleModal = () => {
        this.setState({
            isOpen: !this.state.isOpen,
        });
      };
      getAllMessages = async () => {
        await axios.get(`${this.baseUrl}/admin/messages`).then(res => {
          if (res.data) {
            this.setState({ messages: res.data.data });
          }
        }); 
      };
     // Delete a message
     deleteMessage = ({ messageId, index }, event) => {
      event.preventDefault();
  
      // Prevent the admin to mistakenly delete a message
      if (!window.confirm("Delete this message?"))
      return;
      axios
        .delete(`${this.baseUrl}/admin/message/${messageId}`)
        .then(res => {
          this.setState({
            errorMessage: res.data.message
          })
          //Remove from list
          this.setState(state => {
            const messages = state.messages;
  
            messages.splice(index, 1);
            return { messages };
          });
        })
        .catch(error => console.log("Could not delete message"));
    };
    // Delete all messages
    deleteAllMessage = (event) => {
      event.preventDefault();
  
      // Prevent the admin to mistakenly delete messages
      if (!window.confirm("Delete all of messages?"))
      return;
      axios
        .delete(`${this.baseUrl}/admin/messages/delete`)
        .then(res => {
          this.setState({
            errorMessage: res.data.message
          })
          //Remove from list
          this.setState(state => {
            const messages = state.messages;
            return { messages };
          });
        })
        .catch(error => console.log("Could not delete messages"));
    };
    setToLocalStorage = (message) => {
      localStorage.setItem('message', message.id);
    }
    render() { 
        return ( 
            <Row>
              <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row>
                    <Col lg="10"><h3 className="mb-0">All messages</h3></Col>
                    <Col lg="2">
                      <Badge 
                        color="danger"
                        href="#"
                        onClick={this.deleteAllMessage.bind(this)}
                        >
                          Delete all messages
                      </Badge>
                    </Col>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Sender names</th>
                      <th scope="col">Sender email</th>
                      <th scope="col">Sender phone</th>
                      <th scope="col">Message</th>
                      <th scope="col">At</th>
                      <th scope="col">Actions</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.messages.map(( message, index ) => {
                      return (
                        <tr key={message.id}>
                          <td>{message.sendernames}</td>
                          <td>{message.senderemail}</td>
                          <td>{message.senderphone}</td>
                          <td>{message.message.substr(0, 20)}...
                          </td>
                          <td>{message.sentat}</td>
                          <td>
                            <Badge 
                                color="primary" 
                                className="badge-dot mr-4"
                                href="#"
                                onClick={() => {
                                  this.toggleModal()
                                  this.setState({msgId: message.id})
                                }}
                                >
                                <i className="bg-info" />
                                  View message
                              </Badge>
                              <MessageModal 
                                isOpen={this.state.isOpen}
                                msgId={this.state.msgId}
                                onHandleOpen={() => this.toggleModal()} />

                            <Badge 
                                color="danger"
                                href="#"
                                onClick={this.deleteMessage.bind(this, {
                                    messageId: message.id,
                                    index
                                  })}
                                >
                                  Delete this message
                              </Badge>
                            </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
                <CardFooter className="py-4">
                  
                </CardFooter>
              </Card>
            </div>
          </Row>
         );
    }
}
 
export default Messages;