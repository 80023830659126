import React from "react";
import axios from "axios";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
  UncontrolledAlert,
  Spinner,
} from "reactstrap";
import "assets/scss/am-group.scss?v1.1.0";

class FormModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      namesErrorMessage: "",
      emailErrorMessage: "",
      phoneErrorMessage: "",
      messageErrorMessage: "",
      messages: [],
      loading: false,
      companyInfo: [],
    };
    this.baseUrl = `https://am-group.herokuapp.com/api/v1`;
  }
  componentDidMount = () => {
    this.getCompanyInfo();
  };

  toggleModal = () => {
    this.props.onHandleOpen();
  };
  // INSERTING A MESSAGE

  storeMessage = async (event) => {
    event.preventDefault();
    const form = event.target;
    const { sendernames, senderemail, senderphone, message } = form.elements;

    if (sendernames.value.length === 0) {
      this.setState({ namesErrorMessage: "Tell us your name please" });
    } else {
      this.setState({ namesErrorMessage: "" });
    }
    if (senderemail.value.length === 0) {
      this.setState({ emailErrorMessage: "Your email address please" });
    } else {
      this.setState({ emailErrorMessage: "" });
    }
    if (senderphone.value.length === 0) {
      this.setState({
        phoneErrorMessage: "Your phone so that we can get right back to you",
      });
    } else {
      this.setState({ phoneErrorMessage: "" });
    }
    if (message.value.length === 0) {
      this.setState({ messageErrorMessage: "Tell us something!" });
    } else {
      this.setState({ messageErrorMessage: "" });
    }
    this.setState({ loading: true });
    axios
      .post(`${this.baseUrl}/contact`, {
        sendernames: sendernames.value,
        senderemail: senderemail.value,
        senderphone: senderphone.value,
        message: message.value,
      })
      .then((res) => {
        this.setState({ isCreating: false });
        this.setState((state) => {
          const messages = state.messages;
          messages.push(res.data.data);
          return { messages };
        });
        console.log(res.data.message);
        form.reset();
      })
      .catch((error) => {
        this.setState({ loading: false });
        this.setState({
          errorMessage: "Could not send your message please try again",
        });
      });
    this.setState({
      errorMessage:
        "We have received your message we will get back to you soon, now tap or click the close me button or outside of me to close me",
    });
    this.setState({ loading: false });
  };

  getCompanyInfo = async () => {
    await axios.get(`${this.baseUrl}/amcompany`).then((res) => {
      if (res.data) {
        this.setState({ companyInfo: res.data.data });
      }
    });
  };
  render() {
    const { isOpen } = this.props;
    let color = "";
    if (
      this.state.errorMessage === "Could not send your message please try again"
    ) {
      color = "danger";
    } else {
      color = "success";
    }
    return (
      <>
        <Modal
          className="modal-dialog-centered"
          size="sm"
          isOpen={isOpen}
          toggle={() => this.toggleModal()}
        >
          <div className="modal-body p-0">
            <Card className="bg-secondary shadow border-0">
              <CardHeader className="pb-3">
                <div className="text-muted text-center mb-3">
                  <small>Reach out to us with our social media</small>
                </div>
                <div className="btn-wrapper text-center">
                  {this.state.companyInfo.map((info) => {
                    return (
                      <div key={info.id}>
                        <Button
                          className="btn-icon-only rounded-circle"
                          color="twitter"
                          href={info.twitterlink}
                        >
                          <i className="fa fa-twitter" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="warning"
                          href={info.instgramlink}
                        >
                          <i className="fa fa-instagram" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="primary"
                          href={info.linkdinlink}
                        >
                          <i className="fa fa-linkedin" />
                        </Button>
                      </div>
                    );
                  })}
                </div>
              </CardHeader>
              <CardBody>
                <div className="text-center text-muted mb-4">
                  <small>Or simply ping us a message</small>
                </div>
                {this.state.errorMessage ? (
                  <UncontrolledAlert color={color}>
                    <p>{this.state.errorMessage}</p>
                  </UncontrolledAlert>
                ) : null}
                <Form onSubmit={this.storeMessage.bind(this)} role="form">
                  <FormGroup>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fa fa-user" aria-hidden="true"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Your names"
                        id="sendernames"
                        type="text"
                      />
                    </InputGroup>
                    {this.state.namesErrorMessage ? (
                      <small className="text-danger m-3">
                        {this.state.namesErrorMessage}
                      </small>
                    ) : null}
                  </FormGroup>
                  <FormGroup>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fa fa-envelope" aria-hidden="true"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email"
                        id="senderemail"
                        type="email"
                      />
                    </InputGroup>
                    {this.state.emailErrorMessage ? (
                      <small className="text-danger m-3">
                        {this.state.emailErrorMessage}
                      </small>
                    ) : null}
                  </FormGroup>

                  <FormGroup>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fa fa-mobile" aria-hidden="true"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Your phone number"
                        type="phone"
                        id="senderphone"
                        autoComplete="off"
                      />
                    </InputGroup>
                    {this.state.phoneErrorMessage ? (
                      <small className="text-danger m-3">
                        {this.state.phoneErrorMessage}
                      </small>
                    ) : null}
                  </FormGroup>
                  <FormGroup className="mb-4">
                    <Input
                      cols="80"
                      id="message"
                      placeholder="Type a message here..."
                      rows="4"
                      type="textarea"
                    />
                  </FormGroup>
                  {this.state.messageErrorMessage ? (
                    <small className="text-danger m-3">
                      {this.state.messageErrorMessage}
                    </small>
                  ) : null}
                  <div className="text-center">
                    <Button className="my-4" color="success" type="submit">
                      Send your message
                      {this.state.loading ? (
                        <span className="pl-2">
                          <Spinner size="sm" color="primary" />
                        </span>
                      ) : (
                        ""
                      )}
                    </Button>
                    <Button
                      className="ml-auto bg-gradient-default"
                      color="default"
                      data-dismiss="modal"
                      type="button"
                      onClick={() => this.toggleModal()}
                    >
                      Close me
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Modal>
      </>
    );
  }
}

export default FormModal;
