import React from "react";
import { NavLink as NavLinkRRD, Link, Redirect } from "react-router-dom";
import axios from "axios";
import { PropTypes } from "prop-types";
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col
} from "reactstrap";
import "assets/scss/am-group-dashboard.scss";

class Sidebar extends React.Component {
  state = {
    collapseOpen: false,
    adminInfo: [],
    redirect:false
  };
  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
    this.baseUrl = "https://am-group.herokuapp.com/api/v1";
    this.logout = this.logout.bind(this);
  }
  logout(e) {
    e.preventDefault();
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    localStorage.removeItem("Names");
    this.setState({ redirect: true });
  }
  componentDidMount() {
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem(
      "token"
    );
    this.getAdminInfo();
  }
  getAdminInfo = async () => {
    await axios.get(`${this.baseUrl}/admin/profile`).then(res => {
      if (res.data) {
        this.setState({ adminInfo: res.data.data });
      }
    }); 
  };
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  toggleCollapse = () => {
    this.setState({
      collapseOpen: !this.state.collapseOpen
    });
  };
  closeCollapse = () => {
    this.setState({
      collapseOpen: false
    });
  };
  createLinks = routes => {
    return routes.map((prop, key) => {
      return (
        <NavItem key={key}>
          <NavLink
            to={prop.layout + prop.path}
            tag={NavLinkRRD}
            onClick={this.closeCollapse}
            activeClassName="active"
          >
            <i className={prop.icon} />
            {prop.name}
          </NavLink>
        </NavItem>
      );
    });
  };
  render() {
    if (this.state.redirect === true) {
      return <Redirect to={"/"} />
    }
    const { routes, logo } = this.props;
    let navbarBrandProps;
    if (logo && logo.innerLink) {
      navbarBrandProps = {
        to: logo.innerLink,
        tag: Link
      };
    } else if (logo && logo.outterLink) {
      navbarBrandProps = {
        href: logo.outterLink,
        target: "_blank"
      };
    }
    return (
      <Navbar
        className="navbar-vertical fixed-left navbar-light bg-white"
        expand="md"
        id="sidenav-main"
      >
        <Container fluid>
          <button
            className="navbar-toggler"
            type="button"
            onClick={this.toggleCollapse}
          >
            <span className="navbar-toggler-icon" />
          </button>
          {logo ? (
            <NavbarBrand className="pt-0" {...navbarBrandProps}>
              <img
                alt={logo.imgAlt}
                className="navbar-brand-img"
                src={logo.imgSrc}
              />
            </NavbarBrand>
          ) : null}
          <Nav className="align-items-center d-md-none">
           
            <UncontrolledDropdown nav>
              {this.state.adminInfo.map(admin => {
                return (
                  <div key={admin.id}>
                    <DropdownToggle nav>
                      <Media className="align-items-center">
                        <span className="avatar avatar-sm rounded-circle">
                          <img
                            alt="..."
                            src={require("assets/img/profile/profile.png")}
                          />
                        </span>
                      </Media>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-arrow" right>
                      
                      <DropdownItem to="/admin/profile" tag={Link}>
                        <i className="ni ni-single-02" />
                        <span>My profile</span>
                      </DropdownItem>
                      
                      <DropdownItem to="/" target="_blank" tag={Link}>
                        <i className="fa fa-arrow-right" aria-hidden="true"></i>
                        <span>View as vistor</span>
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem 
                        href="/" 
                        onClick={this.logout}>
                        <i className="fa fa-sign-out" aria-hidden="true"></i>
                        <span>Logout</span>
                      </DropdownItem>
                    </DropdownMenu>
                  </div>
                )
              })}
            </UncontrolledDropdown>
          </Nav>
          <Collapse navbar isOpen={this.state.collapseOpen}>
            <div className="navbar-collapse-header d-md-none">
              <Row>
                {logo ? (
                  <Col className="collapse-brand" xs="6">
                    {logo.innerLink ? (
                      <Link to={logo.innerLink}>
                        <img alt={logo.imgAlt} src={logo.imgSrc} />
                      </Link>
                    ) : (
                      <a href={logo.outterLink}>
                        <img alt={logo.imgAlt} src={logo.imgSrc} />
                      </a>
                    )}
                  </Col>
                ) : null}
                <Col className="collapse-close" xs="6">
                  <button
                    className="navbar-toggler"
                    type="button"
                    onClick={this.toggleCollapse}
                  >
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            <Nav navbar>{this.createLinks(routes)}</Nav>
            <hr className="my-3" />
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}

Sidebar.defaultProps = {
  routes: [{}]
};

Sidebar.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    innerLink: PropTypes.string,
    outterLink: PropTypes.string,
    imgSrc: PropTypes.string.isRequired,
    imgAlt: PropTypes.string.isRequired
  })
};

export default Sidebar;
